import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Register.css";
import TextField from "../../../Utils/TextBubbles/textField";
import TextBubbles from "../../../Utils/TextBubbles/textBubbles";
import TeamBubbles from "../../../Utils/TextBubbles/teamBubbles";
import BoldTextButton from "../../../Utils/TextBubbles/boldTextButton";

function CreateTeamSpace() {
    const [textInput, setTextInput] = useState("");
    const [textList, setTextList] = useState([]);
    const [subTeamName, setSubTeamName] = useState([]);
    const [showDoneButton, setShowDoneButton] = useState(false);
    const navigate = useNavigate();
    const name = localStorage.getItem("name");

    //initialize the all state variables
    useEffect(() => {
        setTextList([]);
        setTextInput("");
        setSubTeamName([]);
        setShowDoneButton(false);
        setTextList(previous => previous.concat({ text: `Welcome aboard ${name}! Lets setup the teams for your users. Type a Team Name into the text field below and hit enter. Voila you will have created your first team!`, origin: "machine", bold:'normal' }));
        setTimeout(() => {
            setTextList(previous => previous.concat({ text: `Here are your teams, let me know when your done by typing "I'm Done" `, origin: "machine", bold: 'bold' }));
        }, 3200);
    }, []);

    //Handle when the user clicks the button to go to the next page
    const handleClick = () => {
        navigate("/signup/admin/invite");
    };

    //Handle when the user hits enter on the text field
    const handleInput = (event) => {
        event.preventDefault();

        if (textInput.trim().toLowerCase() === "i'm done") {
            setShowDoneButton(true); // Show the BoldTextButton
            sessionStorage.setItem("subTeamName", JSON.stringify(subTeamName));
            return;
        } else {
            setTimeout(() => {
                setSubTeamName(previous => [...previous, textInput]);
                setTextList(previous => {
                    // If this is the first user-originated message, create a new entry
                    if (previous.length === 1) {
                        return previous.concat({ text: [textInput], origin: "user" });
                    } else {
                        // For subsequent items, add them to the list as an array of one string
                        return [...previous, { text: [textInput], origin: "user" }];
                    }
                });
            }, 100);
        }
        setTextInput("");
    };

    //Map the textList to the TextBubbles component
    function mapTextList() {
        return textList.map((text, index) => {
            let current = false;
            if (index === textList.length - 1) {
                current = true;
            }
            if (text.origin === "machine") {
                return <TextBubbles text={text.text} origin={text.origin} current={current} key={index} bold={text.bold} />;
            } else if (text.origin === "user") {
                return <TeamBubbles teamName={text.text} index={index} key={index}/>;
            }
        });
    }

    return (
        <div className="leftPanel">
            <div className="functionPanel">
                <div>
                    <div>
                        <h1 className="ChatUIHeader">Create Team Space (1/2)</h1>
                    </div>
                    {mapTextList()}
                </div>
                {showDoneButton ? 
                    <BoldTextButton text="Click me if your done!" handleClick={handleClick} className="BoldTextButton" />
                    :
                    <TextField input={textInput} setInput={setTextInput} handleSubmit={handleInput} placeholder={"Type in a Team Name..."} />
                }
            </div>
            <div className="col-md-4"></div>
        </div>
    )
}

export default CreateTeamSpace;