import axios from 'axios';

const getQuestion = async ({AreaOfFocus}) => {
    try {
        const data = {
            "AreaOfFocus": AreaOfFocus,
        }
        const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/create/get-question',
        JSON.stringify(data)
        );

        return response.data;

    } catch (error) {
        console.error('Error fetching question:', error);
        throw error;
    }
};

export default getQuestion;
