import "./HeaderButton.css";

function HeaderButton({ buttonIconOn, buttonIconOff, buttonText, buttonState, onClick }){

    return (
        <button className="header-button" onClick={onClick}>
            <div className="header-button-row">
                <img
                    src={buttonState ? buttonIconOn : buttonIconOff}
                    alt="icon"
                    className="header-button-icon"
                />
                <span
                    className="header-button-text"
                    style={
                        buttonState
                            ? { color: "#F21C50", fontWeight: "bold", fontSize: "20px" }
                            : { color: "#404040", fontWeight: "bold", fontSize: "20px" }
                    }
                >
                    {buttonText}
                </span>
            </div>
        </button>
    );
}

export default HeaderButton;