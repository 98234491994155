import DemographicElement from "../SentimentElement/DemographicElement";
import dem_black from "../../assets/dem_black.png";
import { useEffect, useState } from "react";

function DemographicList({setDemographicBar, selectedFilters}){

    const [displayText, setDisplayText] = useState("");

    useEffect(() => {
        console.log("This is the selected filters", selectedFilters);

        let displayedBody;

        const hasActiveFilter = selectedFilters.some(filter => filter.active);
        if (!hasActiveFilter) {
            displayedBody = "No filters applied";
        } else {
            displayedBody = selectedFilters
                .filter(filter => filter.active)
                .map(filter => filter.filterKey)
                .join(", ") + " filters are currently active";
        }
        
        setDisplayText(displayedBody);
    }, [selectedFilters]);

    

    return(
        <div className="summary-elements">
            <DemographicElement text={displayText} icon={dem_black} setDemographicBar={setDemographicBar}/>
        </div>
    )
}

export default DemographicList;