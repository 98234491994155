
// Import Assets
import "./CreateElementTab.css";
import RemoveButtonIcon from "../../../Assets/StaticIcons/RemoveButtonIcon.svg";

function CreateElementTabs({ children, icon, handleClick, state, handleRemove, handleMouseEnter, handleMouseLeave }) {

    return (
        <button
            className="select-element"
            onClick={handleClick}
            style={{ backgroundColor: state ? "#FFACC0" : "#FFD4DF" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="select-element-icon-box">
                <img src={icon} alt="icon" className="select-element-icon" />
            </div>
            {children}
            {
                state
                    ?
                    <button className="remove-button" onClick={handleRemove}>
                        <p className="remove-button-text">Remove</p>
                        <img src={RemoveButtonIcon} alt="remove" className="remove-button-icon" />
                    </button>
                    :
                    null
            }

        </button>
    )
}

export default CreateElementTabs;
