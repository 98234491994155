
//Import Components here
import AddMoreElement from "./AddMoreElement/AddMoreElement";

//Import assets here
import "./AddMore.css";

function AddMore({addMoreFilters, selectedFilter, setSelectedFilter}) {

    const handleFilterSelect = (filter) => {
        if (selectedFilter.length < 5) {
            setSelectedFilter(prevSelectedFilter => [...prevSelectedFilter, filter]);
        }
    }
    
    return (
        <div className="add-filter-add-window">
            <h1 className="add-filter-add-window-title">
                More Filters
            </h1>
            {
                addMoreFilters?.map((filter, index) => {
                    return (
                        <AddMoreElement
                            key={index}
                            title={filter.FilterName}
                            subtitle={filter.FilterDescription}
                            onElementClick={() => handleFilterSelect(filter)}
                        />
                    );
                })
            }
            <div className="space-vertical-10px"/>
        </div>
    )
}

export default AddMore;