
/* 
Here is the schema for the selectedFilters state variable:

selectedFilters: [
    {
        filterKey: filterKey,
        filtersActive:[
            {identifier: identifier, display: display}
        ]
    },
    {}
]

Here is the schema for the surveyData state variable:

surveyData: [
    {
        "EmotionLabel": [
            "CONTENTMENT",
            "CONTENTMENT"
        ],
        "filterKey":"filterVal.identifier",
    }
]
*/
function filterSurveyData(surveyData, selectedFilters) {
    let included = [];

    /* console.log("Selected Filters:", selectedFilters); */

    if (!Array.isArray(surveyData) || !Array.isArray(selectedFilters)) {
        return included;
    }

    let isSelected = [];
    selectedFilters.forEach((filter, fIdx) => {
        if (filter.active) {
            isSelected.push(true);
        } else {
            isSelected.push(false);
        }
    });

    //If no filters are active, include all the data
    if (!isSelected.some(val => val === true)) {
        surveyData.forEach((surveyItem, idx) => {
            included.push(surveyItem.EmotionLabel);
        });

    //If at least one filter is active, filter the data
    } else {
        surveyData.forEach((surveyItem, idx) => {
            selectedFilters.forEach((filter, fIdx) => {

                //If the filter is not sactive, skip the loop entirely
                if(filter.active === false){
                    return; // skip the loop

                //If the filter is active, filter the data
                } else if (filter.active === true){
                    if (surveyItem.hasOwnProperty(filter.filterKey)) {
                        const surveyValIdentifier = surveyItem[filter.filterKey].toString();
        
                        if (Array.isArray(filter.filtersActive) && filter.filtersActive.length > 0) {
                            for (const activeFilter of filter.filtersActive) {
                                if (surveyValIdentifier === activeFilter.identifier) {
                                    included.push(surveyItem.EmotionLabel);
                                    break;  // Exits the loop when condition is met
                                }
                            }
                        } 
                    }
                }
            });
        });
    }

    return included;
}


function getEmotionAtIndex(data, index, filter) {
    const included = filterSurveyData(data, filter);
     // Adjust index since it's likely coming from a 1-based system
    const emotionList = [];

    if (Array.isArray(included) && included.length !== 0) {
        included.forEach((item, idx) => {
            let emotion = item[index];
            if (emotion) {
                emotionList.push(emotion);
            }
        });
    }

    // Count the number of every unique value in the list
    let emotionCount = emotionList.reduce((acc, curr) => {
        if (typeof acc[curr] === 'undefined') {
            acc[curr] = 1;
        } else {
            acc[curr] += 1;
        }
        return acc;
    }, {});

    // Define the order of emotions
    const emotionsOrder = ['CONTENTMENT', 'RELIEF', 'CONFLICTED', 'SADNESS', 'ANGER'];

    // Create a new object with emotions in the desired order
    let emotionCountList = [];
    emotionsOrder.forEach((emotion) => {
        if (emotionCount[emotion]) {
            let orderedEmotionCount = {};
            orderedEmotionCount['name'] = emotion;
            orderedEmotionCount['value'] = emotionCount[emotion];
            emotionCountList.push(orderedEmotionCount);
        }
    });

    // Calculate the percentage of each emotion and multiply it by totalheight
    const totalCount = emotionList.length;
    emotionCountList.map((emotion) => {
        const ratio = emotion.value / totalCount;
        //const roundedRatio = Math.round(ratio * 10) / 10;
        emotion.value = parseFloat(ratio.toFixed(3));
    })

    // Return the orderedEmotionCount object and the total count as an object
    return { emotionCount: emotionCountList, total: totalCount };
}

export default getEmotionAtIndex;