
//Import libraries 
import {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";

//Import components 
import CreatePanelTitle from "../../Util/CreatePanelTitle/CreatePanelTitle";
import NavigateButton from "../../Util/NavigateButton/NavigateButton";
import CreateElementTabs from "../../Util/CreateElementTab/CreateElementTab";

//Import the assets
import "./OptionsDetail.css";
import BackArrowGrey from "../../../Assets/StaticIcons/BackArrowGrey.svg";
import SelectElementIcon from "../../../Assets/StaticIcons/SelectElementIcon.svg";

function OptionsDetail(){
    const navigate = useNavigate();
    const {selectedFilters} = useOutletContext();
    const {filterIndex} = useParams();
    const [filter, setFilter] = useState(selectedFilters ? selectedFilters[filterIndex] : {});

    useEffect(() => {
        setFilter(selectedFilters ? selectedFilters[filterIndex] : {});
    }, [filterIndex]);

    useEffect(() => {
        console.log(filter);
    }, [filter]);


    return(
        <div className="create-function-panel-background">
            <div className="create-function-panel-body">
                <div>
                    <div className="create-function-panel-title-row">
                        <CreatePanelTitle titleText="Filter Options" />
                    </div>
                    <div className="option-detail-options-box">
                    {   
                        Array.isArray(filter.FilterOptions)
                        ? 
                        filter.FilterOptions.map((option, index) => {
                            return (
                                <CreateElementTabs icon={SelectElementIcon}>
                                    {option.identifier}
                                </CreateElementTabs>
                            );
                        })
                        : 
                        null
                    }
                    </div>
                </div>
                <div className="create-function-panel-bottom-buttons">
                    <NavigateButton text="Back to Filters" icon={BackArrowGrey} handleClick={() => navigate(-1)} leftIcon={true} />
                </div>
            </div>
        </div>
    )
}

export default OptionsDetail;