import axios from 'axios';

const getFilters = async () => {
    try {
        const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/create/get-filters'
        );
        return response.data;

    } catch (error) {
        console.error('Error fetching question:', error);
        throw error;
    }
};

export default getFilters;