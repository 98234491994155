import "./SentimentElement.css";

function SentimentElement({ name, icon, number, color, setTextList, handlePromptSubmit }) {

    const lowercaseName = name.toLowerCase();
    const percentage = (number * 100).toFixed(1) + '%';
    const text = "People feeling " + lowercaseName;

    const handleClick = () => {
        setTextList(previous => previous.concat({ text: [`Explain for me why people feel ${lowercaseName}`], origin: "user" }));
        handlePromptSubmit(`Explain for me why people feel ${lowercaseName}`);
    }

    return (
        <button className="sentiment-element" onClick={handleClick}>
            <div className="sentiment-element-headerblock">
                <div className="sentiment-element-icon-box" style={{ backgroundColor: `${color}` }}>
                    <img src={icon} alt="icon" className="sentiment-element-icon" />
                </div>
                <div className="sentiment-element-text">
                    {text}
                </div>
            </div>
            <div className="sentiment-element-number">
                {percentage}
            </div>
        </button>
    )
}

export default SentimentElement;
