import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import backIcon from '../assets/back_icon.svg';
import chat_grey from '../assets/chat_grey.svg';
import board_grey from '../assets/board_grey.svg';
import chat_red from '../assets/chat_red.svg';
import sent_grey from '../assets/sent_grey.svg';
import sent_red from '../assets/sent_red.svg';
import './ResultHeader.css';
import HeaderButton from './HeaderButton/HeaderButton';

function ResultHeader({ setClipboardBar }) {
    const [promptButtonState, setPromptButtonState] = useState(true);
    const [sentimentButtonState, setSentimentButtonState] = useState(false);
    const [backButtonState, setBackButtonState] = useState(false); //TODO: Implementing the back button State for hover
    const navigate = useNavigate();
    const TeamID = sessionStorage.getItem('TeamID');

    const handlePromptClick = () => {
        setPromptButtonState(true);
        setSentimentButtonState(false);
        navigate('');
    };

    const handleSentimentClick = () => {
        setPromptButtonState(false);
        setSentimentButtonState(true);
        navigate('sentiment');
    }

    const handleBackButtonClick = () => {
        navigate(`/home/${TeamID}`);
    };

    const handleClipboardClick = () => {
        console.log("Clicked on clipboard button");
        setClipboardBar(true);
    }

    return (
        <div className="result-header-box">
            <HeaderButton buttonIconOff={backIcon} buttonIconOn={backIcon} buttonText={"Back"} buttonState={backButtonState} onClick={handleBackButtonClick} />
            <div className="navbar-box">
                <HeaderButton buttonIconOff={chat_grey} buttonIconOn={chat_red} buttonText={"Prompts"} buttonState={promptButtonState} onClick={handlePromptClick} />
                <HeaderButton buttonIconOff={sent_grey} buttonIconOn={sent_red} buttonText={"Sentiment"} buttonState={sentimentButtonState} onClick={handleSentimentClick} />
            </div>
            <HeaderButton buttonIconOff={board_grey} buttonText={"Clipboards"} buttonState={false} onClick={handleClipboardClick} />
        </div>
    );
}


export default ResultHeader;