import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./AdminWelcome.css";

function AdminNamePage (){
    const [Name, setName] = useState("");
    const navigate = useNavigate();
  
    const handleSubmit = () => {
      localStorage.setItem("role", "admin");
      localStorage.setItem("name", Name);
      navigate(`${Name}`);
    };

    return (
        <div className="leftPanel">
            <div className="functionPanel">
              <div>
                <h1 className="hello">Hello There!</h1>
                <h2 className="Subtitle mb-2">Welcome to the factors platform!</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="text-input-hello"
                    placeholder="Start Typing Your Name..."
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="col-md-4"></div>
          </div>
    )
}

export default AdminNamePage;