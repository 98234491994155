//Import the necessary libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//Import the components
import FunnelButton from './Buttons/FunnelButton';

//Import the assets
import './FunnelPage.css';
import logoBlack from '../../Assets/logoBlack.svg';

function FunnelPage() {

    const navigate = useNavigate();

    const handleNavigateCreate = () => {
        navigate(`/create`);
    }

    return (
        <div className='funnel-page-background'>
            <img src={logoBlack} alt={"Logo"} className="funnel-logo" />
            <div className='funnel-body'>
                <FunnelButton Title={"Surveys"} Subtitle={"Gauge your student's opinion"} handleClick={handleNavigateCreate} />
                <div className='funnel-page-spacer'/>
                <FunnelButton Title={"Quizzes"} Subtitle={"Create in-class quizzes"} handleClick={handleNavigateCreate} />
            </div>
        </div>
    );
}

export default FunnelPage;