
import React, { useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function RedirectComponent() {

  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  console.log(user)

  //Make API call to verify if the user is registered. If not, redirect to registration page
  const NavigateToRegistration = useCallback(() => {
    const userRole = localStorage.getItem('role');
    console.log(userRole);

    async function makeApiCall() {
      try {

        const data = {
          email: user.email,
        }

        const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/register/checkuser',
          JSON.stringify(data)
        );

        console.log(response.data);

        if (response.data.body === "User is not registered" && userRole) {

          const timer = setTimeout(navigate(`/signup`), 1000)

          return (
            clearTimeout(timer)
          )
        }
        
        if(response.data.body.TeamID && userRole){
          //navigate to home page. 
          sessionStorage.setItem("TeamID", response.data.body.TeamID);
          sessionStorage.setItem("Username", response.data.body.Username);

          //temporary
          navigate(`/home/${response.data.body.TeamID}`)
        } 

      } catch (error) {
        console.log(error);
        return null;
      }
    }

    
    makeApiCall();
  });

  useEffect(() => {
    isAuthenticated ? NavigateToRegistration() : navigate('/');
  }, [NavigateToRegistration]);

  return (
    <div className="position-fixed w-100 overflow-hidden">
      <div className="text-center d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
export default RedirectComponent;
