// Date: 12/16/2023

// Import dependencies & libraries
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// Import components here
import CreatePanelTitle from "../Util/CreatePanelTitle/CreatePanelTitle";
import CreateElementTabs from "../Util/CreateElementTab/CreateElementTab";
import NavigateButton from "../Util/NavigateButton/NavigateButton";
import UtilButton from "../Util/UtilButton/UtilButton";
import AccessCodeModal from "./AccessCodeModal/AccessCodeModal";
import PublishModal from "./PublishModal/PublishModal";

// Import assets here
import "./AddFilter.css";
import { useHover, useModal } from "../Util/Hooks";
import BackArrowGrey from "../../Assets/StaticIcons/BackArrowGrey.svg";
import ForwardArrowWhite from "../../Assets/StaticIcons/ForwardArrowWhite.svg";
import SelectElementIcon from "../../Assets/StaticIcons/SelectElementIcon.svg";
import UtilButtonIcon from "../../Assets/StaticIcons/UtilButtonIcon.svg";
import AddMore from "./AddMore/AddMore";

function AddFilter() {
    const {
        navigate,
        selectedFilters,
        setSelectedFilters,
        addMoreFilters,
        survey,
        getTeamID,
        setFilters,
        setSurveyID,
        setSurveyName,
        setAccessCode,
        setError
    } = useOutletContext();

    const {
        hoveredIndex,
        handleMouseEnter,
        handleMouseLeave
    } = useHover(selectedFilters);

    const {
        showWindow,
        publishWarning,
        showAccessCodeModal,
        showPublishModal,
        pageAccessCode,
        surveyNameInput,
        setSurveyNameInput,
        handleOpenPublishModal,
        handleClosePublishModal,
        handleCloseAccessCodeModal,
        handleOpenAddMore,
        handlePublish
    } = useModal();

    useEffect(() => {
        setFilters(selectedFilters);
    }, [selectedFilters]);

    const handleCopyAccessCode = () => {
        navigator.clipboard.writeText(survey.AccessCode);
    };

    const handleMoveToOptionsDetail = (index) => {
        navigate(`/create/${index}`);
    }

    const handleBackButton = () => {
        navigate(`/home/${getTeamID()}`)
    }

    const handleRemove = (index) => {
        setSelectedFilters(prevSelectedFilter => prevSelectedFilter.filter((_, i) => i !== index));
    };

    return (
        <div className="create-function-panel-background">
            <div className="create-function-panel-body">
                <div>
                    <div className="create-function-panel-title-row">
                        <CreatePanelTitle titleText="Demographic Filters" />
                        <button className="create-function-panel-button" onClick={handleOpenAddMore}>Add filter to survey</button>
                        {showWindow && (
                            <AddMore
                                selectedFilter={selectedFilters}
                                setSelectedFilter={setSelectedFilters}
                                addMoreFilters={addMoreFilters}
                            />)
                        }
                    </div>
                    {
                        Array.isArray(selectedFilters)
                            ?
                            selectedFilters.map((filter, index) => {
                                return (
                                    <CreateElementTabs
                                        key={index}
                                        icon={SelectElementIcon}
                                        state={hoveredIndex[index]}
                                        handleMouseEnter={() => handleMouseEnter(index)}
                                        handleMouseLeave={handleMouseLeave}
                                        handleRemove={() => handleRemove(index)}
                                    >
                                        <div
                                            className='template-element-row-box'
                                        >
                                            <div className='template-element-child-column'>
                                                <h2 className='template-element-child-title' style={{ color: "#000" }}>{filter.FilterName}</h2>
                                                <p className='template-element-child-subtitle' style={{ color: "#000" }}>Filter for {filter.FilterKey}</p>
                                            </div>

                                            {
                                                hoveredIndex[index]
                                                    ?
                                                    <div className='template-element-child-column'>
                                                        <UtilButton text={"View Survey Question"} icon={UtilButtonIcon} handleClick={() => handleMoveToOptionsDetail(index)} />
                                                    </div>
                                                    :
                                                    <div className='template-element-row-box'>
                                                        <div className='template-element-child-column'>
                                                            <h2 className='template-element-child-title' style={{ color: "#808080" }}>{filter.FilterLength} Options</h2>
                                                            <p className='template-element-child-subtitle' style={{ color: "#808080" }}>Total length</p>
                                                        </div>
                                                        <div className='template-element-child-column'>
                                                            <h2 className='template-element-child-title' style={{ color: "#808080" }}>{filter.FilterParticipantProfile}</h2>
                                                            <p className='template-element-child-subtitle' style={{ color: "#808080" }}>Rec. Participants</p>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </CreateElementTabs>
                                )
                            })
                            :
                            null
                    }
                </div>
                <AccessCodeModal
                    AccessCode={pageAccessCode}
                    showModal={showAccessCodeModal}
                    handleCloseModal={handleCloseAccessCodeModal}
                    handleBackButton={handleBackButton}
                    handleCopyAccessCode={handleCopyAccessCode}
                    UtilButtonIcon={UtilButtonIcon}
                />
                <PublishModal
                    showPublishModal={showPublishModal}
                    handleClosePublishModal={handleClosePublishModal}
                    publishWarning={publishWarning}
                    surveyNameInput={surveyNameInput}
                    setSurveyNameInput={setSurveyNameInput}
                    handlePublish={handlePublish}
                    setAccessCode={setAccessCode}
                    survey={survey}
                    setSurveyName={setSurveyName}
                    setSurveyID={setSurveyID}
                    setError={setError}
                />
                <div className="create-function-panel-bottom-buttons">
                    <NavigateButton text="Back to Focus" icon={BackArrowGrey} handleClick={() => navigate("/create/question")} leftIcon={true} />
                    <NavigateButton text="Finish!" icon={ForwardArrowWhite} highlight={true} handleClick={handleOpenPublishModal} />
                </div>
            </div>
        </div>
    );
}

export default AddFilter;

/* 
Drafts & Test code
const [selectedFilters, setSelectedFilters] = useState(DummyFilters);
const [addMoreFilters, setAddMoreFilters] = useState(AddMoreFilters); 

  */
