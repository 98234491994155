import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import Modal from "react-modal"
import axios from "axios"
import Sidebar from './SideBar/Sidebar';
import Header from "../Header/Header"
import './HomePage.css';
import SurveyBoxList from './surveyBox/surveyBox';

Modal.setAppElement(document.getElementById('root'));

function HomePage() {
  const { TeamID } = useParams()
  const navigate = useNavigate()
  const [team, setTeam] = useState({
    Admins: ["loading..."],
    SubTeams: ["loading..."]
  })
  const [surveys, setSurveys] = useState([
    {
      SurveyID: 1, SurveyName: "loading...",
      Category: "loading...",
      NumOfParticipants: "loading..."
    }])

  useEffect(() => {
    async function makeApiCall() {
      try {
        const data = {
          "TeamID": TeamID,
        }
        const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/home/initialize',
          JSON.stringify(data)
        );
        console.log(response.data.Team);

        //Set the team state to the response data
        setTeam(response.data.Team);

        //Set the survey state to the response data
        setSurveys(response.data.Survey);

      } catch (error) {
        console.log(error);
        return null;
      }
    }
    makeApiCall();
  }, []);

  const handleNavigateCreateSurvey = () => {
    navigate(`/create`);
  }

  return (
    <div className='container'>
      <div className='row mb-5'>
        <Header />
      </div>

      <div className='row'>
        <div className='col-3 p-0'>
          <Sidebar team={team} />
        </div>

        <div className='col-9 pe-0'>
          <div className='row justify-content-end'>
            <div className='col-lg-4 col-md-6 col-sm-8 col-12 d-flex justify-content-end'>
              <div>
                <button className='d-flex justify-content-around align-items-center mb-5 create-survey' onClick={handleNavigateCreateSurvey}>
                  <h4 className='my-0 mx-2 py-1 px-2 create-survey-plus'>+</h4>
                  <p className='my-2 create-survey-text'>Create New Survey</p>
                </button>
              </div>
            </div>
          </div>

          <SurveyBoxList surveys={surveys} navigate={navigate} TeamID={TeamID} />

        </div>
      </div>
    </div>
  );
}

export default HomePage