import React, { useEffect, useState } from 'react';

function SurveyBoxList({ surveys, navigate, TeamID }) {

    // WebSocket connection
    /* const [ws, setWs] = useState(null);
    const [messages, setMessages] = useState([]);
    const wsUrl = 'wss://vb9gpqopm1.execute-api.us-east-1.amazonaws.com/development/';

    useEffect(() => {
        console.log("messages currently are added: ", messages);
    }, [messages])

    const handleButtonClick = async (value) => {
        if (!ws || ws.readyState !== WebSocket.OPEN) {
            try {
                // Create a promise that resolves when the WebSocket is open
                const socket = new WebSocket(wsUrl);
                await new Promise((resolve, reject) => {
                    socket.onopen = () => {
                        console.log('WebSocket connection opened');
                        resolve();
                    };
                    socket.onerror = (error) => {
                        console.error('WebSocket error:', error);
                        reject(error);
                    };
                });
    
                // Set up other WebSocket event handlers
                socket.onmessage = (event) => {
                    const newMessage = event.data;
                    setMessages((prevMessages) => [...prevMessages, newMessage]);
                };
                socket.onclose = () => console.log('WebSocket connection closed');
    
                // Update WebSocket state
                setWs(socket);
    
                // Send message
                const payload = { SurveyID: value, TeamID: TeamID, action: "initialize" };
                sendMessage(socket, payload);
    
            } catch (error) {
                console.error('Error establishing WebSocket connection:', error);
            }
        } else {
            // Send message if WebSocket is already open
            const payload = { SurveyID: value, TeamID: TeamID, action: "initialize" };
            sendMessage(ws, payload);
        }
    };
    
    // Function to send messages
    const sendMessage = (socket, payload) => {
        socket.send(JSON.stringify(payload));
    };

    const disconnectWebSocket = () => {
        if (ws) {
            ws.close();
            setWs(null);
        }
    }; */

    const surveyElements = surveys.map((survey, index) => {
        const toResultFunction = () => {
            console.log("to result function");
            navigate(`/results/${survey.SurveyID}`);
        }
        return (
            <div className='tab-main-box' key={survey.SurveyID}>
                <div className='survey'>
                    <div className='survey-child'>
                        <h3 className='text-center survey-title'>{survey.SurveyName}</h3>
                        <div>
                            {/* <button className='survey-close-button' onClick={() => handleButtonClick(survey.SurveyID)}>
                                <span className='survey-close-button-text'> Close Survey</span>
                            </button>
                            <button className='survey-close-button' onClick={disconnectWebSocket}>
                                <span className='survey-close-button-text'> Close Connection</span>
                            </button> */}
                        </div>

                    </div>
                </div>
                <button
                    className='results'
                    onClick={toResultFunction}>
                    Results
                </button>
            </div>
        )
    })

    return <>{surveyElements}</>;
}

export default SurveyBoxList;