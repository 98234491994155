import "./QuestionSideBarElement.css";
import select_question_element from "../../assets/select_question_element.svg";

function QuestionSideBarElement({ text, index, active, handleSelectQuestion, setSelectedQuestion }) {
    let color;
    let bold;
    let fontSize;
    let maxWidth;

    switch (active) {
        case true:
            bold = "600";
            fontSize = "15px";
            maxWidth = "350px";
            color = "#4B9CFF"
            break;
        case false:
            bold = "normal";
            maxWidth = "350px";
            fontSize = "15px";
            color = "#B3D2F6"
            break;
        default:
            maxWidth = "350px";
            bold = "normal";
            fontSize = "15px";
            color = "#B3D2F6"
            break;
    }
    const shortText = text.split(" ").slice(0, 12).join(" ") + (text.split(" ").length > 10 ? "..." : "");

    const handleClick = () => {
        handleSelectQuestion(index);
        setSelectedQuestion(index);
        console.log("Selected question: " + index);
    }

    return (
        <div className="question-sidebar-element" onClick={handleClick}>
            <div className="question-sidebar-element-icon-box" style={{ backgroundColor: `${color}` }}>
                <img src={select_question_element} alt="icon" className="sidebar-element-icon" />
            </div>
            <div className="question-sidebar-element-textbox" style={{ maxWidth: `${maxWidth}` }}>
                <h3 className="question-sidebar-element-title">
                    Question {index + 1}
                </h3>
                <span 
                    style={{ fontSize: `${fontSize}`, fontWeight: `${bold}` }} 
                    className="question-sidebar-element-text"
                >
                    {active ? text : shortText}
                </span>
            </div>
        </div>
    )
}

export default QuestionSideBarElement;