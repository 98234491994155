import "./DemographicSideBarElement.css";
import dem_white from "../../assets/dem_white.svg";
import optionsBar from "../../assets/optionsBar.svg";
import { useState } from "react";

function DemographicSideBarElement({ active, handleFilterActive, text, values, selectedFilters, setSelectedFilters, index }) {
    const [isOpen, setIsOpen] = useState(false);
    let displayText;
    let color;
    let bold;
    let fontSize;
    let maxWidth;

    switch (active) {
        case true:
            bold = "600";
            fontSize = "17px";
            maxWidth = "330px";
            displayText = `People who identify as ${selectedFilters.filtersActive.map(filter => filter.identifier).join(', ')}`
            color = "#4B9CFF"
            break;
        case false:
            bold = "normal";
            maxWidth = "350px";
            fontSize = "18px";
            displayText =  `There is no ${text} filter selected`
            color = "#B3D2F6"
            break;
        default:
            displayText = `There is no ${text} filter selected`
            maxWidth = "350px";
            bold = "normal";
            fontSize = "18px";
            color = "#B3D2F6"
            break;
    }

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    const handleButtonClick = (event) => {
        const updatedFilters = [...selectedFilters.filtersActive];
        if (updatedFilters.length !== 0) {
            const filterIndex = updatedFilters.findIndex(filter => filter.identifier === event.identifier);
            if (filterIndex !== -1) {
                updatedFilters.splice(filterIndex, 1);
            } else {
                updatedFilters.push({ identifier: event.identifier, display: event.display });
            }
            console.log(updatedFilters);
            const isActive = handleFilterActive(index, updatedFilters);
            setSelectedFilters(previous => {
                const newFilters = [...previous];
                newFilters[index] = { filterKey: text, filtersActive: updatedFilters, active: isActive };
                return newFilters;
            });
        } else {
            updatedFilters.push({ identifier: event.identifier, display: event.display });
            console.log(updatedFilters);
            const isActive = handleFilterActive(index, updatedFilters);
            setSelectedFilters(previous => {
                const newFilters = [...previous];
                newFilters[index] = { filterKey: text, filtersActive: updatedFilters, active: isActive  };
                return newFilters;
            }); 
        }
        
    }

        return (
            <div>
                <button className="sidebar-element" onClick={handleClick}>
                    <div className="sidebar-element-headerblock">
                        <div className="sidebar-element-icon-box" style={{ backgroundColor: `${color}` }}>
                            <img src={dem_white} alt="icon" className="sidebar-element-icon" /> 
                        </div>
                        <div className="sidebar-element-text" style={{maxWidth:`${maxWidth}`}}>
                            <span style={{fontSize:`${fontSize}`, fontWeight:`${bold}`}}>{displayText}</span>
                        </div>
                    </div>
                </button>
                {isOpen && (
                    <div className="sidebar-element-options-list">
                        <div className="sidebar-element-options-bar" style={{ height: '100%' }}>
                            <img src={optionsBar} alt="stretched bar" />
                        </div>
                        <ul style={{ listStyleType: 'none', padding: 0 }} className="sidebar-element-options">
                        {values ? values.map((value, index) => (
                            <li key={index} style={{ margin: '5px 0' }}>
                                <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => handleButtonClick(value)}>
                                    {value.display}
                                </button>
                            </li>
                        )): <li>No values</li>}
                        </ul>
                    </div>
                )}
            </div>
    )
}

export default DemographicSideBarElement;