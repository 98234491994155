import { useState, useEffect } from 'react';

export function useHover(listVariable){
    const [hoveredIndex, setHoveredIndex] = useState(
        listVariable?.map(() => false)
    );

    useEffect(() => {
        setHoveredIndex(listVariable ? listVariable.map(() => false) : []);
    }, [listVariable]);

    const handleMouseEnter = (index) => {
        const updatedHover = hoveredIndex.map((_, idx) => idx === index);
        setHoveredIndex(updatedHover);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(hoveredIndex.map(() => false));
    };

    return {
        hoveredIndex,
        handleMouseEnter,
        handleMouseLeave
    };
}