import SideBar from "./SideBar/SideBar";
import NoticeBlock from "./Notice/Notice";
import ClipboardText from "./ClipboardText/ClipboardText";
import { DeleteClipboard } from "./api/DeleteClipboard";
import { EditClipboard } from "./api/EditClipboard";
import arrowBack from "../assets/arrowBack.svg";
import "./OverallSideBar.css";

function ClipboardSideBar({ clipboardBar, closeSidebar, clipboardData, TeamID, SurveyID, setClipboardData }) {

    const handleDeleteClick = ({ index, UniqueID }) => {
        console.log("Delete text: " + index);
        console.log("UniqueID: " + UniqueID);
        DeleteClipboard({ TeamID: TeamID, SurveyID: SurveyID, UniqueID: UniqueID });
        setClipboardData(prevData => {
            const newData = [...prevData];
            newData.splice(index, 1);
            return newData;
        });
    }

    const handleTitleChange = ({ index, UniqueID, Title }) => {
        console.log("Edit text: " + index);
        console.log("UniqueID: " + UniqueID);
        console.log("Title: " + Title);
        EditClipboard({ TeamID: TeamID, SurveyID: SurveyID, UniqueID: UniqueID, Title: Title });
    }

    return (
        <SideBar isOpen={clipboardBar} onClose={closeSidebar} zHeight={1001}>
            <div className="clipboardsidebar-main">
                <button className="demographic-sidebar-back-button" onClick={closeSidebar}>
                    <img src={arrowBack} alt="Back Arrow to homepage" />
                </button>
                <h1 className="demographic-sidebar-filter-title">
                    Clipboard
                </h1>
                <NoticeBlock
                    titleText={"Its a shared clipboard!"}
                    bodyText={"Content on this clipboard is be shared among your teammate. Please give it a descriptive title!"}>
                </NoticeBlock>
                <div className="clipboard-text-box">
                    {clipboardData ? clipboardData.map((data, index) => {
                        return (
                            <ClipboardText
                                key={index}
                                text={data.Content}
                                originalTitle={data.Title}
                                index={index}
                                UniqueID={data.UniqueID}
                                handleDeleteClick={handleDeleteClick}
                                handleTitleChange={handleTitleChange} />
                        )
                    }) :
                        <div className="clipboard-title">
                            No text saved to clipboard
                        </div>}
                </div>
            </div>

        </SideBar>
    )
}

export default ClipboardSideBar;