export function generateRandomCode() {
    let code = '';
    for (let i = 0; i < 9; i++) {
        // Generate a random integer from 0 to 9
        const digit = Math.floor(Math.random() * 10);
        code += digit.toString();

        // Add dashes after every third digit except the last
        if (i === 2 || i === 5) {
            code += '-';
        }
    }
    return code;
}