import button_icon from "../../assets/button_icon.png";
import "./BoldTextButton.css";

function BoldTextButton({ handleClick, text, className}) {
    return (
        <button style={{ backgroundColor: "transparent", border: "none", display: "flex", alignItems: "center"}} onClick={handleClick} className={className}>
            <span style={{ color: "#494949", fontSize: "25px", fontWeight: "bold", fontFamily: "Poppins" }}>{text} </span>
            <img src={button_icon} alt="button icon" style={{ marginLeft: "10px", height: "20px" }} />
        </button>
    )
}

export default BoldTextButton;