import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from './assets/Logo_1.png';
import profile from "./assets/profile.png"
import './Header.css';
import { Link } from "react-router-dom";
import Modal from "react-modal"
import { useAuth0 } from '@auth0/auth0-react';

Modal.setAppElement(document.getElementById('root'));

function Header() {

  const { user } = useAuth0();
  const [ profileModalIsOpen, setProfileModalIsOpen ] = useState(false)

  function closeProfileModal(){
    setProfileModalIsOpen(false)
  }

  const customStyles = {
    content: {
      top: '40%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: "24px",
      boxShadow: "0px 4px 10px 5px rgba(13, 21, 92, 0.23)",
      maxWidth: "900px",
      minHeight: "100px",
    },
  };


  return (
    <header className="container">
      <nav className="row mt-4 d-flex justify-content-between align-items-center nav">
        <div className="col-4">
          {/* Change to /home after merging with registration */}
          <Link to="/home">
            <img className="p-3 nav-logo" src={logo} alt="logo" />
          </Link>

        </div>
        <div className="col-4 d-flex justify-content-end dropdown">
          <button
            className="d-flex justify-content-between align-items-center nav-profile"
            onClick={() => setProfileModalIsOpen(true)}
          >
            <div>
              <img className="pe-2 nav-profile-img" src={profile} alt="profile icon"/>
              {user.name}
            </div>
          </button>

        </div>
      </nav>

      <Modal
        isOpen={profileModalIsOpen}
        onRequestClose={closeProfileModal}
        style={customStyles}
        contentLabel="Delete Suvery Confirmation Modal"
      >
        <div>
          <button
            className='position-absolute end-0 top-0 modal-exit-button'
            onClick={closeProfileModal}
          >
            &#x2716;
          </button>
          <div className="d-flex flex-column align-items-center">
            <h3 className="mb-3 modal-profile-title">User Profile</h3>
            <div>
              <h4 className="modal-field">{user.name}</h4>
              <h4 className="modal-field">{user.email}</h4>
            </div>
          </div>

        </div>

      </Modal>
    </header>
  )
};

export default Header;