
import "./AddMoreElement.css";
import Icon from "../../../../Assets/StaticIcons/SelectElementIcon.svg";

function AddMoreElement({ title, subtitle, onElementClick }) {

    return (
        <div onClick={onElementClick} className="add-more-element-row-box">
            <div className="add-more-element-icon-box">
                <img src={Icon} alt="icon" className="add-more-element-icon" />
            </div>
            <div className='add-more-element-child-column'>
                <h2 className='add-more-element-child-title' style={{ color: "#000" }}>{title}</h2>
                <p className='add-more-element-child-subtitle' style={{ color: "#000" }}>{subtitle}</p>
            </div>
        </div>
    );
}

export default AddMoreElement;