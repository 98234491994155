import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import BoldTextButton from "../../Utils/TextBubbles/boldTextButton";
import "./AdminWelcome.css";

function AdminSigninPage() {
    const name = localStorage.getItem("name");
    const { loginWithRedirect } = useAuth0();

    const handleClick = () => {
        loginWithRedirect();
    };

    return (
            <div className="leftPanel">
                <div className="functionPanel">
                    <div>
                        <h1 className="hello">Hey {name}!</h1>
                        <h2 className="Subtitle mb-2">Good to see you here! Signin Button Below:</h2>
                    </div>
                    <BoldTextButton text="Sign-in Button right here" handleClick={handleClick} />
                </div>
                <div className="col-md-4"></div>
            </div>
    )
}

export default AdminSigninPage;