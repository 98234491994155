import axios from 'axios';

export async function initializeSurveyApiCall(TeamID, SurveyID, setFilters, setEmotionData, setQuestionData, setSummary, setClipboardData) {
    try {
        const data = {
            TeamID: TeamID,
            SurveyID: SurveyID
        };
        const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/result/survey',
            JSON.stringify(data)
        );

        //Set the filters
        console.log(response.data.Filters);
        setFilters(response.data.Filters);

        //Set the emotion data
        console.log(response.data.EmotionBrk);
        setEmotionData(response.data.EmotionBrk);

        //Set the question data
        console.log(response.data.SurveyData);
        setQuestionData(response.data.SurveyData);

        //Set the summary data
        console.log(response.data.Summary);
        setSummary(response.data.Summary);

        //Set the clipboard data
        if(response.data.Clipboard){
            console.log(response.data.Clipboard);
            setClipboardData(response.data.Clipboard);
        } else {
            setClipboardData([]);
        }

    } catch (error) {
        console.log(error);
        return null;
    }
}