// Date: 12/15/2023

//Import Assets here
import "./CreatePanelTitle.css";
import TitleIcon from "../../../Assets/StaticIcons/TitleIcon.svg";

function CreatePanelTitle({ titleText }) {
    return (
        <div className="create-panel-title-body">
            <img src={TitleIcon} alt="Back Arrow to homepage" className="create-panel-title-icon" />
            <h1 className="create-panel-title-text">{titleText}</h1>
        </div>

    )
}

export default CreatePanelTitle;