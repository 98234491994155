import "../../WelcomePage/AdminWelcome/AdminWelcome.css";

function TextField({handleSubmit, input, setInput, placeholder}){
    return(
        <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="text-input"
                    placeholder={placeholder}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                  />
                </div>
              </form>
    )
}

export default TextField;