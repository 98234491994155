import { useState, useEffect } from 'react';
import { CheckAccessCode } from '../API/CheckAccessCode';
import { putSurvey } from '../API/PutSurvey';
import { generateRandomCode } from '../RandomCode/RandomCode';

export function useModal (){
    const [showWindow, setShowWindow] = useState(false);
    const [pageAccessCode, setPageAccessCode] = useState(null);
    const [publishWarning, setPublishWarning] = useState(null); 
    const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [surveyNameInput, setSurveyNameInput] = useState("");

    const handleOpenPublishModal = () => {
        setShowPublishModal(true);
    }

    const handleClosePublishModal = () => {
        setShowPublishModal(false);
    }

    const handleOpenAccessCodeModal = () => {
        setShowAccessCodeModal(true);
    }

    const handleCloseAccessCodeModal = () => {
        setShowAccessCodeModal(false);
    }

    const handleOpenAddMore = () => {
        setShowWindow(!showWindow);
    };

    const handlePublish = async (setAccessCode, survey, setSurveyName, setSurveyID, setError) => {

        if (surveyNameInput.length < 2) {
            setPublishWarning("Please enter a survey name with at least 2 characters.");
            return;
        }

        let accessCode = survey.AccessCode;
        let counter = 0;

        while (counter < 4) {
            if (accessCode === null) {
                accessCode = generateRandomCode();
            }

            const response = await CheckAccessCode(accessCode);
            if (response) {
                setAccessCode(accessCode);
                setPageAccessCode(accessCode);

                const putResponse = await putSurvey({
                    AccessCode: accessCode,
                    TeamID: survey.TeamID,
                    SurveyID: survey.SurveyID,
                    SurveyName: surveyNameInput,
                    Questions: survey.SurveyQuestions,
                    Filters: survey.Filters
                });

                if (putResponse.status) {
                    setSurveyID(putResponse.SurveyID);
                    break;
                } else {
                    setError(true)
                }
                
            } else {
                accessCode = null;
                counter++;
            }
        }

        setSurveyName(surveyNameInput);
        handleClosePublishModal();
        handleOpenAccessCodeModal();
    }

    useEffect(() => {
        if (surveyNameInput.length >= 2 && publishWarning !== null && showPublishModal === true) {
            setPublishWarning(null);
        }
    }, [surveyNameInput, showPublishModal]
    );

    return {
        showWindow,
        publishWarning,
        pageAccessCode,
        showAccessCodeModal,
        showPublishModal,
        surveyNameInput,
        setSurveyNameInput,
        handleOpenPublishModal,
        handleClosePublishModal,
        handleCloseAccessCodeModal,
        handleOpenAddMore,
        handlePublish
    }
}