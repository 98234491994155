import "./textBubbles.css";

function TeamBubbles({ teamName, index }) {
    let boxColor = "#090E41"; // Default blue color

    return (
        <div style={{ display: 'flex', alignItems: 'start' }}>
            <div style={{ width: '25px', height: '25px', borderRadius: '4px', backgroundColor: boxColor, opacity: '1' }}></div>
            <div style={{ fontSize: '20px', fontFamily: 'Poppins', color: "#000000", opacity: '1' }} className="teamBubbles">
                <p key={index.toString()}>{`${index - 1}. ${teamName}`}</p>
            </div>
        </div>
    )
}

export default TeamBubbles;