import PromptElement from "../PromptElement/PromptElement.js";
import sum_icon from "../../assets/sum_icon.png";

function SummaryList({SummaryPrompts, setTextList, summary, allowQuery, index}){

    const dummyHandle = () => {
        console.log("Dummy handle");
    }
    
    if (Array.isArray(SummaryPrompts) === false){
        return <PromptElement key={"loading"} text={"Loading Summary Prompts..."} icon={sum_icon} summary={true} setTextList={dummyHandle}/>
    }

    return(
        <div className="summary-elements">
            <PromptElement key={index} text={`Summarize all responses I got for Question ${index + 1}`} icon={sum_icon} summary={true} setTextList={setTextList} summaryData={summary} index={index} allowQuery={allowQuery}/>
            {/* {SummaryPrompts.map((prompt, index) => (
                <PromptElement key={index} text={`Summarize all responses I got for Question ${prompt.QuestionNo}`} icon={sum_icon} summary={true} setTextList={setTextList} summaryData={summary} index={index} allowQuery={allowQuery}/>
            ))} */}
        </div>
    )
}

export default SummaryList;