import React from "react";
import { useOutletContext } from "react-router-dom";
import "./Prompts.css";
import SummaryList from "./renderList/SummaryList";
import PromptList from "./renderList/PromptList";
import PageNoticeBlock from "../utils/Notice/Notice";

const PromptsList = [
    "From the data, tell me the most important piece of information, what is the weakness?",
    "Basing on the current data, What is the most common challenge faced by the participants?",
    "From the data, Summarize all the positive responses",
    "Use the current data, what is the biggest area to improve on?",
    "From the current data, where should I begin making changes?",
    "What should be my top three area of concern?"
];

function Prompts() {
    const {setTextList, questionData, summary, handlePromptSubmit, allowQuery, selectedQuestion} = useOutletContext();

    return (
        <div className="prompt-box">
            <PageNoticeBlock 
            titleText={"Select the question!"} 
            bodyText={"If you want the chatbot to look at the responses associated with a specific question, click on 'select question' on your right!"}/>
            <div className="prompt-list-box">
                <div>
                    <h1 className="prompt-box-title">Summarize</h1>
                    <SummaryList SummaryPrompts={questionData} setTextList={setTextList} summary={summary} allowQuery={allowQuery} index={selectedQuestion}/>
                </div>
                <div>
                    <h1 className="prompt-box-title">Prompts</h1>
                    <PromptList PromptsList={PromptsList} setTextList={setTextList} handlePromptSubmit={handlePromptSubmit} allowQuery={allowQuery}/>
                </div>
            </div>
        </div>
    )
}

export default Prompts;