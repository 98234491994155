// Date: 12/15/2023

// Import dependencies & libraries
import React from "react";
import { Outlet } from "react-router-dom";

// Import components here
import BackButton from "../Util/BackButton/BackButton";

// Import assets here
import "./CreatePage.css";
import backIcon from "../../Assets/backIcon.svg";
import { useCreateSurveyVar, useSurvey } from "../Util/Hooks";

function CreatePage() {

    //Create the survey object that stores all the information
    const {
        survey,
        setSurveyName,
        setAccessCode,
        setSurveyQuestions,
        setFilters,
        setSurveyID,
        getSurveyQuestions,
        getFilters,
        getTeamID,
        checkCompletion
    } = useSurvey(sessionStorage.getItem("TeamID"));

    //Create the state variables for the workflow variables of the Create workflow
    const {
        navigate,
        areaOfFocus,
        setAreaOfFocus,
        error,
        setError,
        handleAreaOfFocusSubmit,
        questions,
        edited,
        selectedFilters,
        setSelectedFilters,
        addMoreFilters,
        handleQuestionSubmit
    } = useCreateSurveyVar();

    //For the return home button at the top
    const handleReturnHome = () => {
        navigate(`/home/${getTeamID()}`);
    }

    return (
        <div className="create-page-background">
            <div className="create-page-left-panel">
                <BackButton text="Back to Home Page" icon={backIcon} handleClick={handleReturnHome} />
                {areaOfFocus ? <h1 className="create-page-title">{areaOfFocus}</h1> : <h1 className="create-page-title">Create Page</h1>}
                <div className="placeholder-div-bottom"></div>
            </div>
            {
                !error
                    ? <Outlet context={{
                        navigate,
                        setAreaOfFocus,
                        handleAreaOfFocusSubmit,
                        questions,
                        edited,
                        setError,
                        handleQuestionSubmit,
                        selectedFilters,
                        setSelectedFilters,
                        addMoreFilters,
                        survey,
                        setSurveyName,
                        setAccessCode,
                        setSurveyQuestions,
                        setFilters,
                        setSurveyID,
                        getSurveyQuestions,
                        getFilters,
                        getTeamID,
                        checkCompletion
                    }}
                    />
                    : <>
                        <div> Error have occured, reporting to admin</div>
                    </>
            }
        </div>
    );
}

export default CreatePage;