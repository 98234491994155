import TextBubbles from "../utils/textBubbles";
import './Chat.css';

function ChatCopilot({ textList, setClipboardData, setClipboardBar, SurveyID, TeamID, clipboardData }) {
    return (
        <div className="chat-main-box">
            {
                Array.isArray(textList) && textList.length !== 0 ? textList.map((text, index) => {
                    let current = false;
                    if (index === textList.length - 1) {
                        current = true;
                    }
                    return (
                        <TextBubbles 
                        key={index} 
                        text={text.text} 
                        origin={text.origin} 
                        bold={text.bold} 
                        current={current} 
                        setClipboardData={setClipboardData}
                        clipboardData={clipboardData} 
                        setClipboardBar={setClipboardBar}
                        SurveyID={SurveyID}
                        TeamID={TeamID}
                        />
                    )
                }
                ) : null
            }
        </div>
        
    )
}

export default ChatCopilot;