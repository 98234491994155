//Import dependencies & libraries
import { useState } from "react";

//Import assets 
import "./QuestionTabs.css";

function QuestionTabs ({ question, index, handleCompleteEdit}){
    const [questionText, setQuestionText] = useState(question);

    const handleChange = (value) => {
        setQuestionText(value);
    };

    return (
        <textarea
            type="text"
            value={questionText}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={()=> handleCompleteEdit(index, questionText)}
            className="create-element-input-box"
        />
    );
};

export default QuestionTabs;