import "./textBubbles.css";
import clipboard from "../assets/clipboard.png";
import Typewriter from "./TypeWriter";
import { AddClipboard } from "./api/AddClipboard";
import {useState} from "react";
import { v4 as uuidv4 } from 'uuid';

function TextBubbles({ text, origin, current, bold, setClipboardData, setClipboardBar, SurveyID, TeamID, clipboardData}) {

    //Aesthetic variables
    let boxColor = "#090E41"; // Default blue color
    let colorOpacity = current ? 1 : 0.7;

    //State variables
    const [complete, setComplete] = useState(false);

    //Function to handle saving text to clipboard
    const handleSaveClick = () => {
        if (complete) {
            const UniqueID = uuidv4();

            if (Array.isArray(clipboardData)) {
                setClipboardData((prevSavedTexts) => [...prevSavedTexts, {Content: text, Title: "Set Your Title Here", UniqueID: UniqueID}]);
                console.log("Saved text: " + text);
                setClipboardBar(true);
                AddClipboard({TeamID: TeamID, SurveyID: SurveyID, Content: text, Title: "New Block!", UniqueID: UniqueID});
            } else {
                setClipboardData([{Content: text, Title: "Set Your Title Here", UniqueID: UniqueID}]);
                console.log("Saved text: " + text);
                setClipboardBar(true);
                AddClipboard({TeamID: TeamID, SurveyID: SurveyID, Content: text, Title: "New Block!", UniqueID: UniqueID});
            }
        }
    };

    if (origin === "machine") {
        boxColor = "#FF003E"; // Red color
        return (
            <div style={{ display: 'flex', alignItems: 'start', marginTop:'15px' }} className="text-bubble-container">
                <div style={{ width: '25px', height: '25px', borderRadius: '4px', backgroundColor: boxColor, opacity: colorOpacity}}></div>
                <div style={{ fontSize: '16px', fontFamily: 'Poppins', color: "#000000", opacity: colorOpacity, fontWeight:bold  }} className="textBubbles">
                    <Typewriter text={text} speed={50} setComplete={setComplete}/>
                        <button className="save-button" onClick={handleSaveClick}>
                            <img src={clipboard} alt="icon" className="save-button-icon" />
                            <span className="save-button-text">Save to clipboard</span>
                        </button>
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', alignItems: 'start', marginTop:'10px'}}>
            <div style={{ width: '25px', height: '25px', borderRadius: '4px', backgroundColor: boxColor, opacity: colorOpacity }}></div>
            <div style={{ fontSize: '16px', fontFamily: 'Poppins', color: "#000000", opacity: colorOpacity, fontWeight:bold }} className="textBubbles">{text}</div>
        </div>
    )
}

export default TextBubbles;