import React from 'react';
import "./Sidebar.css";
import groupIcon from "./assets/Group.png"
import userIconTemp from "./assets/UserIconTemp.png"


function Sidebar({team}){

    //Map over subteams to get content in sidebar

    const subteamData = team.SubTeams ? team.SubTeams : []
   
    //Change to handle string when admin is singular
    const admins = team.Admins.map(admin => (
        <div className='d-flex align-items-center pb-4' key={admin}>
          <img className='subteam-user-img' src={userIconTemp} alt="user icon"/>
          <div className='d-flex flex-column'>
            <h4 className='m-0 ps-3 subteam-username'>{admin}</h4>
            <p className='m-0 ps-3 subteam-role'>Admin</p>
          </div>
        </div>
    ))
    
    const subteams = subteamData.length !== 0 ? subteamData.map(subteam => (
        <div className='px-3 pb-5 subteam' key={subteam.Index}>
          <div className='d-flex align-items-center pb-4'>
            <img className="mx-2 subteam-img" src={groupIcon} alt="group icon"/>
            <h4 className='m-0 ps-3 subteam-name'>{subteam}</h4>
          </div>

          {admins}
        </div>
    )) : (
      <div className='px-3 pb-5 subteam'>
        <div className='d-flex align-items-center pb-4'>
          <img className="mx-2 subteam-img" src={groupIcon} alt="group icon"/>
          <h4 className='m-0 ps-3 subteam-name'>Enterprise Team</h4>
        </div>
        {admins}
      </div>
    );

    return (
      <div className='sidebar'>
        <h1 className='text-center pt-5 pb-4 sidebar-title'>{team.TeamName}</h1>
        {subteams}
      </div>
    );
  };
  
export default Sidebar