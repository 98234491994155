import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, speed, setComplete}) => {

  // Split text into segments and identify bold parts
  const segments = text.split('**').reduce((acc, segment, index) => {
    if (index % 2 === 0) {
      // Regular text
      return acc.concat(segment.split(' ').map(word => ({ word, bold: false })));
    } else {
      // Bold text
      return acc.concat(segment.split(' ').map(word => ({ word, bold: true })));
    }
  }, []);

  const [index, setIndex] = useState(0);
  const [currentText, setCurrentText] = useState([]);
  
  useEffect(() => {
    if (index < segments.length) {
      const timeoutId = setTimeout(() => {
        setCurrentText((ct) => ct.concat(segments[index]));
        setIndex((i) => i + 1);
      }, speed);

      return () => clearTimeout(timeoutId);
    } else {
      // Call setComplete with true when all text is rendered
      setComplete(true);
    }
  }, [index, segments, speed, setComplete]);

  return (
    <div>
      {currentText.map((segment, idx) => (
        segment.bold ? 
        <strong key={idx}>{segment.word + ' '}</strong> : 
        <span key={idx}>{segment.word + ' '}</span>
      ))}
    </div>
  );
};

export default Typewriter;
