import "./textBubbles.css";
import Typewriter from "./TypeWriter";

function TextBubbles({ text, origin, current, bold}) {
    let boxColor = "#090E41"; // Default blue color
    let colorOpacity = current ? 1 : 0.5;
    if (origin === "machine") {
        boxColor = "#FF003E"; // Red color
        return (
            <div style={{ display: 'flex', alignItems: 'start' }}>
                <div style={{ width: '25px', height: '25px', borderRadius: '4px', backgroundColor: boxColor, opacity: colorOpacity}}></div>
                <div style={{ fontSize: '20px', fontFamily: 'Poppins', color: "#000000", opacity: colorOpacity, fontWeight:bold  }} className="textBubbles">
                    <Typewriter text={text} speed={50}/>
                </div>
            </div>
        )
    }


    return (
        <div style={{ display: 'flex', alignItems: 'start' }}>
            <div style={{ width: '25px', height: '25px', borderRadius: '4px', backgroundColor: boxColor, opacity: colorOpacity }}></div>
            <div style={{ fontSize: '20px', fontFamily: 'Poppins', color: "#000000", opacity: colorOpacity, fontWeight:bold  }} className="textBubbles">{text}</div>
        </div>
    )
}

export default TextBubbles;