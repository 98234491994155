import "./PromptElement.css";

function PromptElement({text, icon, summary, setTextList, summaryData, index, handlePromptSubmit, allowQuery}){

    const handleClick = () => {
        if(allowQuery === false){
            return;
        }
        setTextList(previous => previous.concat({ text: [text], origin: "user"}));

        // Conditional logic inside handleClick
        if (summary) {
            setTimeout(() => {
                setTextList(previous => previous.concat({ text: `**Here is the summary for Question ${index + 1}:** \n\n ${summaryData[index]}`, origin: "machine"}));
            }, 1500);
        } else {
            handlePromptSubmit(text);
        }
    };
    
    return(
        <button className="prompt-element" onClick={handleClick} disabled={allowQuery === false}>
            <div className="prompt-element-icon-box">
                <img src={icon} alt="icon" className="prompt-element-icon"/>
            </div>
            <div className="prompt-element-text" style={{maxWidth: summary ? "360px" : "400px"}}>
                {text}
            </div>
        </button>    
    )
}

export default PromptElement;
