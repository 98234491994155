//React and React packages imports
import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';

//Import components
import ResultHeader from './Header/ResultHeader';
import ToolBar from './ToolBar/ToolBar';
import ChatCopilot from './Chat/Chat';
import DemographicSideBar from './SideBar/DemographicSideBar';
import ClipboardSideBar from './SideBar/ClipboardSideBar';
import SelectQuestionSideBar from './SideBar/SelectQuestionSideBar';
import TextField from './utils/textField';

//Import styles
import './Results.css';
import down_arrow from './assets/down_arrow.svg';

//Import helper functions
import { handleAgentQuery } from './api/WebsocketUtil';
import { initializeSurveyApiCall } from './api/RestUtil';

const Results = () => {

    //Bottom anchor to help scroll to the bottom of the chat
    const bottomRef = useRef(null);

    //Whether the intro message has been rendered
    const [renderIntro, setRenderIntro] = useState(false);

    //List of textblock rendered on CoPilot Chat Interface
    const [textList, setTextList] = useState([]);

    //The prompt that the user is typing in the text field
    const [textInput, setTextInput] = useState("");

    //List of filters in the survey
    const [filters, setFilters] = useState([]);

    //List of selected filters for emotion
    const [selectedFilters, setSelectedFilters] = useState([]);

    // WebSocket connection
    const [ws, setWs] = useState(null);
    const wsUrl = 'wss://vb9gpqopm1.execute-api.us-east-1.amazonaws.com/development/';

    //Set Demographic Sidebar Status
    const [demographicBar, setDemographicBar] = useState(false);
    const closeDemographicSidebar = () => setDemographicBar(false);

    //Set Clipboard Sidebar Status
    const [clipboardBar, setClipboardBar] = useState(false);
    const closeClipboardSidebar = () => setClipboardBar(false);
    const [clipboardData, setClipboardData] = useState([]);

    //Set Clipboard Sidebar Status
    const [selectQuestionSideBar, setSelectQuestionSideBar] = useState(false);
    const closeSelectQuestionSidebar = () => setSelectQuestionSideBar(false);
    const [selectedQuestion, setSelectedQuestion] = useState(0);

    //Set the sentiment being rendered
    const [emotionData, setEmotionData] = useState([]);
    const [renderQuestions, setRenderQuestions] = useState(false);
    const [questionData, setQuestionData] = useState(null);

    //Allow the user to query the bot
    const [allowQuery, setAllowQuery] = useState(false);

    //Set the summary created 
    const [summary, setSummary] = useState([]);

    //Get the survey ID and TeamID
    const { SurveyID } = useParams();
    const TeamID = sessionStorage.getItem('TeamID');

    //Setup navigation
    const navigate = useNavigate();

    //Set the selected filters to be empty when the filters change
    useEffect(() => {
        setSelectedFilters(Array.isArray(filters) && filters.length !== 0 ? filters.map(filter => ({ filterKey: filter.filterKey, filtersActive: [], active: false })) : []);
    }, [filters]);

    //Get the survey data from the api
    useEffect(() => {
        initializeSurveyApiCall(TeamID, SurveyID, setFilters, setEmotionData, setQuestionData, setSummary, setClipboardData);
        navigate('');
    }, [])

    //Clean up the websocket connection
    useEffect(() => {
        return () => {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.close();
            }
        };
    }, [ws]);

    //Set the initial message from the bot
    useEffect(() => {
        setDemographicBar(false);
        if (renderIntro === false) {
            setTimeout(() => {
                setTextList(previous => previous.concat(
                    {
                        text: `Welcome to the **result page**, I'm your assistant. I will be here to answer any question about the data!`,
                        origin: "machine",
                        bold: 'normal'
                    }));
            }, 600);
            setRenderIntro(true);
        }
    }, []);

    //Set the message about the survey questions
    useEffect(() => {
        setTimeout(() => {
            if (questionData && renderQuestions === false) {
                setTextList(previous => previous.concat({
                    text: `In this survey, you asked the following questions:\n\n ${questionData.map((question, index) => {
                        return `${index + 1}. ` + question.Question + "\n";
                    }).join('')} \n Feel free to ask me any question about the data!`,
                    origin: "machine",
                    bold: 'normal'
                }));
                setRenderQuestions(true);
            }
        }, 3000);
        setTimeout(() => {
            setAllowQuery(true);
        }, 6500);
    }, [questionData]);

    //Create the bottom anchor to help scroll to the bottom of the chat
    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [textList]);

    //setTextList(prevTextList => prevTextList.concat({text: response.data.result, origin: "machine"}));

    //Open the select question sidebar
    const handleSelectQuestionSideBar = () => {
        setSelectQuestionSideBar(true);
    }

    //Handle when the user submits a message
    const handleTextFieldSubmit = (event) => {
        event.preventDefault();
        if (textInput != "") {
            handleAgentQuery(
                SurveyID, textInput, selectedQuestion, TeamID, ws, wsUrl, setWs, setTextList
            );
            setTextList(previous => previous.concat({ text: [textInput], origin: "user" }));
            setTextInput("");
        }
    };

    const handlePromptSubmit = (prompt) => {
        //setTextList(previous => previous.concat({ text: [prompt], origin: "user" }));
        handleAgentQuery(
            SurveyID, prompt, selectedQuestion, TeamID, ws, wsUrl, setWs, setTextList
        );
    };

    return (
        <div className='BackGround'>
            <ResultHeader setClipboardBar={setClipboardBar} />
            <div className='result-body'>
                <div className='result-body-leftwidget'>
                    <ToolBar setSelectQuestionSideBar={setSelectQuestionSideBar} />
                </div>
                <div className='result-body-chat'>
                    <div className='result-body-chat-texts'>
                        <div className='chatcopilot-question-title-block' onClick={handleSelectQuestionSideBar}>
                            <div className='chatcopilot-question-title-block-button'>
                            <h1 className='chatcopilot-question-title'>
                                Question {selectedQuestion + 1}
                            </h1>
                            <img src={down_arrow} alt='down arrow' className='chatcopilot-question-title-icon' />
                            </div>
                        </div>
                        <ChatCopilot
                            textList={textList}
                            setClipboardData={setClipboardData}
                            setClipboardBar={setClipboardBar}
                            SurveyID={SurveyID}
                            TeamID={TeamID}
                            clipboardData={clipboardData} />
                        <div ref={bottomRef} className='bottomref' />
                    </div>
                    <TextField
                        handleSubmit={handleTextFieldSubmit}
                        input={textInput}
                        setInput={setTextInput}
                        placeholder={"Type any question about the data..."}
                        allowQuery={allowQuery} />
                </div>
                <div className='result-body-rightwidget'>
                    <Outlet context={{ setTextList, setDemographicBar, selectedFilters, emotionData, questionData, summary, handlePromptSubmit, allowQuery, selectedQuestion }} />
                </div>
            </div>
            <SelectQuestionSideBar
                selectQuestionSideBar={selectQuestionSideBar}
                closeSelectQuestionSidebar={closeSelectQuestionSidebar}
                questionData={questionData}
                setSelectedQuestion={setSelectedQuestion}
            />
            <ClipboardSideBar
                clipboardBar={clipboardBar}
                closeSidebar={closeClipboardSidebar}
                clipboardData={clipboardData}
                SurveyID={SurveyID}
                TeamID={TeamID}
                setClipboardData={setClipboardData} />
            <DemographicSideBar
                demographicBar={demographicBar}
                closeSidebar={closeDemographicSidebar}
                filters={filters}
                setSelectedFilters={setSelectedFilters}
                selectedFilters={selectedFilters} />
        </div>
    );
};

export default Results;
