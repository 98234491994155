import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getQuestion from '../API/GetQuestion';
import getFilters from '../API/GetFilter';

export function useCreateSurveyVar() {

    //workflow system meta variables 
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    //workflow functional variables
    const [areaOfFocus, setAreaOfFocus] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [edited, setEdited] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [addMoreFilters, setAddMoreFilters] = useState([]);
    const [filterInitialized, setFilterInitialized] = useState(false);

    const handleAreaOfFocusSubmit = async (areaOfFocus) => {
        try {
            const response = await getQuestion({ AreaOfFocus: areaOfFocus });
            setQuestions(response);

            // Console log the API response
            console.log("Successfully generated the questions ", response);
        } catch (error) {
            setError(error);
        }
    }

    const handleQuestionSubmit = async () => {

        if (!filterInitialized) {
            try {
                const filters = await getFilters();
                setSelectedFilters(filters.default);
                setAddMoreFilters(filters.all);
                setEdited(true);

                // Console log the API response
                console.log("Successfully initialized the filters: ", filters);
            } catch (error) {
                setError(error);
            } finally {
                setFilterInitialized(true);
            }

        }
    }

    return { 
        navigate,
        areaOfFocus, 
        setAreaOfFocus, 
        error, 
        setError,
        handleAreaOfFocusSubmit, 
        questions,
        edited,
        selectedFilters, 
        setSelectedFilters, 
        addMoreFilters, 
        handleQuestionSubmit 
    };
}
