import React, { useEffect, useState } from "react";
import Modal from "react-modal"
import { useNavigate } from "react-router-dom";
import "../Register.css";
import TextBubbles from "../../../Utils/TextBubbles/textBubbles";
import BoldTextButton from "../../../Utils/TextBubbles/boldTextButton";
import { useAuth0 } from '@auth0/auth0-react';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

function InviteTeam() {
    const [textList, setTextList] = useState([]);
    const [showDoneButton, setShowDoneButton] = useState(false);
    const { user } = useAuth0();
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    /**
     *  The following are required to create a user
     * 
     * - @param {string} Name
     * - @param {string} Username (email)
     * - @param {string} Role
     * - @param {string} TeamID
     * */
    const Name = localStorage.getItem("name");
    const Role = localStorage.getItem("role");
    const Username = user.email;
    const TeamID = uuidv4();

    /**
     * The following are required to create a team
     * 
     * - @param {array} Admins
     * - @param {array} SubTeams
     */
    const Admins = [Name];
    const SubTeams = JSON.parse(sessionStorage.getItem("subTeamName"));
    console.log(SubTeams);

    //Render the text bubbles to the screen
    useEffect(() => {
        setTextList([]);
        setShowDoneButton(false);
        setTextList(previous => previous.concat({
            text: `Awesome ${Name}! Now let's invite your teammates to join you on the platform! I'll show you a button below to copy a link and a short message to your clipboard.`,
            origin: "machine",
            bold: 'normal'
        }));

        const ShowSecondText = () => {
            setTextList(previous => previous.concat({
                text: `Click the button below to copy the link to your clipboard. Then paste it into an email or text message to your teammates.`,
                origin: "machine",
                bold: 'bold'
            }));
        };

        const ShowButton = () => {
            setShowDoneButton(true);
        };

        const timerText = setTimeout(ShowSecondText, 2400);
        const timerButton = setTimeout(ShowButton, 4000);

        // Corrected cleanup function
        return () => {
            clearTimeout(timerText);
            clearTimeout(timerButton);
        };
    }, [Name]); // add 'first' to the dependency array


    //Handle when the user clicks the button to go to dashboard
    const handleClick = () => {
        const inviteText =
            "Hey there! I created a team on Factors platform - the first data platform to makes student experience easy. \n\n I'd like you to join me on the platform. Click the link below to join the team. \n\n";
        const link = `http://localhost:3000/user-welcome/${TeamID}`;
        const signature = `\n\n ${Name}`;

        navigator.clipboard.writeText(`${inviteText}${link}${signature}`).then(
            function () {
                console.log("Async: Copying to clipboard was successful!");
                setShowPopup(true); 
            },
            function (err) {
                console.error("Async: Could not copy text: ", err);
            }
        );

        async function makeApiCall() {
            try {

                const data = {
                    Name: Name,
                    Username: Username,
                    Role: Role,
                    TeamID: TeamID,
                    Admins: Admins,
                    SubTeams: SubTeams
                };

                const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/register/admin',
                    JSON.stringify(data)
                );
                console.log(response.data);

            } catch (error) {
                console.log(error);
                return null;
            }
        }

        makeApiCall();
        sessionStorage.setItem("TeamID", TeamID);
        sessionStorage.setItem("Username", Username);

        setTimeout(() => {
            navigate(`/home/${TeamID}`);
        }, 1000); // 1 second delay
        
    };

    //Map the textList to the TextBubbles component
    function mapTextList() {
        return textList.map((text, index) => {
            let current = false;
            if (index === textList.length - 1) {
                current = true;
            }
            return (<TextBubbles text={text.text} origin={text.origin} current={current} key={index} bold={text.bold} />)
        });
    }


    const customStyles = {
        content: {
            top: '40%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: "24px",
            boxShadow: "0px 4px 10px 5px rgba(13, 21, 92, 0.23)",
            maxWidth: "900px",
            minHeight: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            fontWeight: "bold"
        },
    };

    return (
        <div className="leftPanel">
            <div className="functionPanel">
                <div>
                    <div>
                        <h1 className="ChatUIHeader">Invite teams (2/2) </h1>
                    </div>
                    {mapTextList()}
                </div>

                {showDoneButton ?
                    <BoldTextButton text="Click me to copy the link!" handleClick={handleClick} className="BoldTextButton" />
                    :
                    <div />
                }
            </div>
            <Modal isOpen={showPopup} style={customStyles}>
                <span>Successfully copied</span>
            </Modal>
            <div className="col-md-4"></div>
        </div>
    )
}

export default InviteTeam;