// Date: 12/15/2023

// Import dependencies & libraries
import React, {useState} from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

// Import components here
import CreatePanelTitle from "../Util/CreatePanelTitle/CreatePanelTitle";
import NavigateButton from "../Util/NavigateButton/NavigateButton";

// Import assets here
import "./AreaOfFocus.css";
import ForwardArrowWhite from "../../Assets/StaticIcons/ForwardArrowWhite.svg";


function AreaOfFocus() {

  //For storing the text area input
  const [textAreaInput, setTextAreaInput] = useState("") ; 

  //For storing the warning message
  const [warningMessage, setWarningMessage] = useState(""); 

  //For using the context from outlet
  const {
    navigate,
    setAreaOfFocus, 
    handleAreaOfFocusSubmit
  } = useOutletContext();

  //Handle every change made within the text area widget.
  //Set the "textAreaInput" variable to the value of the text area widget.
  const handleTextAreaChange = (event) => {
    setTextAreaInput(event.target.value); 
    setWarningMessage("");
  };

  //When the submit button is clicked, set the area of focus to the text area input.
  //Call the handleAreaOfFocusSubmit function to get the questions.
  //Reset the text area input to an empty string.
  //TODO: Navigate to next page
  const handleAreaOfFocusSubmitButton = () => {
    const wordCount = textAreaInput.trim().split(/\s+/).length;

    if (wordCount < 4) {
      // Set warning message and do not proceed
      setWarningMessage("Please enter at least 4 words.");
      return;
    } else if (wordCount > 20) {
      setWarningMessage("Please enter less than 20 words.");
    }
    setAreaOfFocus(textAreaInput);
    handleAreaOfFocusSubmit(textAreaInput);
    setTextAreaInput("");
    navigate("question");
  }

  return (
    <div className="create-function-panel-background">
      <div className="create-function-panel-body">
        <CreatePanelTitle titleText="Enter the Area of Focus" />
        <div className="create-function-spacer-50px-vertical"/>
        <p className="area-of-focus-instruction-text space-left-3vw">
          <strong>Please input the area of focus of the survey in the text box below.</strong> A friendly neighborhood AI will generate a few topics based on the area of focus you entered, which you can edit on the next page!
        </p>
        {warningMessage && <p className="area-of-focus-instruction-text space-left-3vw">{warningMessage}</p>}
        <textarea className="area-of-focus-textarea" placeholder="Enter the area of focus here...(less than 20 words)" onChange={handleTextAreaChange} value={textAreaInput}></textarea>
        <div className="area-of-focus-submit-row">
          <p className="area-of-focus-instruction-text"><strong>Here is a example</strong> How accessible is technology at my school for students?</p>
          <NavigateButton text="Submit Focus" handleClick={handleAreaOfFocusSubmitButton} icon={ForwardArrowWhite} highlight={true}/>
        </div>
      </div>
    </div>
  );
}

export default AreaOfFocus;