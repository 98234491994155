import select_question from "../../assets/select_question.svg";
import "./ToolBarElement.css";

function ToolBarElement({onClick}){
    return(
        <div className="toolbar-element-box" onClick={onClick}>
            <img src={select_question} className="toolbar-element-icon" alt="select question icon"/>
            <span className="toolbar-element-text">Select Question</span>
        </div>
    )
}

export default ToolBarElement;