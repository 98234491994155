//Create a WebSocket connection and send a message to the WebSocket server
//Import to Results.js:
/**
 * Handles the agent query by establishing a WebSocket connection and sending messages.
 * @param {string} value - The value of the query.
 * @param {WebSocket} ws - The WebSocket object.
 * @param {function} setWs - The function to set the WebSocket object.
 * @param {function} setMessages - The function to set the messages received from the WebSocket.
 * @param {string} TeamID - The ID of the team.
 * @param {string} wsUrl - The URL of the WebSocket server.
 * @param {function} disconnectWebSocket - The function to disconnect the WebSocket.
 * @param {function} sendMessage - The function to send a message through the WebSocket.
 * @returns {void}
 */
// Function to handle agent query
export const handleAgentQuery = async (SurveyID, Query, Index, TeamID, ws, wsUrl, setWs, setTextList) => {
    if (!ws || ws.readyState !== WebSocket.OPEN) {
        try {
            // Create a promise that resolves when the WebSocket is open
            const socket = new WebSocket(wsUrl);
            await new Promise((resolve, reject) => {
                socket.onopen = () => {
                    console.log('WebSocket connection opened');
                    resolve();
                };
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    reject(error);
                };
            });
            // Set up other WebSocket event handlers
            socket.onmessage = (event) => {
                try {
                    const message = JSON.parse(event.data);
    
                    if (message && typeof message.content === 'string' && typeof message.isComplete === 'boolean') {
                        if (message.isComplete) {
                            console.log("message isComplete = True", message);
                            disconnectWebSocket(socket);  // Use the newly created socket instance
                        } else {
                            console.log("message", message);
                            updateTextList(message, setTextList);
                        }
                    } else {
                        console.error("Invalid message format received:", event.data);
                    }
                } catch (error) {
                    console.error("Error parsing WebSocket message:", error);
                }
            };

            socket.onclose = () => console.log('WebSocket connection closed check 2');

            // Update WebSocket state
            setWs(socket);

            // Send message
            const payload = { SurveyID: SurveyID, TeamID: TeamID, action: "agent", Query: Query, Index: Index };
            sendMessage(socket, payload);

        } catch (error) {
            console.error('Error establishing WebSocket connection:', error);
        }
    } else {
        // Send message if WebSocket is already open
        const payload = { SurveyID: SurveyID, TeamID: TeamID, action: "agent", Query: Query, Index: Index };
        sendMessage(ws, payload);
    }
};

// Function to send messages
export const sendMessage = (socket, payload) => {
    socket.send(JSON.stringify(payload));
};

export const disconnectWebSocket = (ws) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
        console.log("WebSocket connection closed");
    }
};

//&& ws.readyState === WebSocket.OPEN

const updateTextList = (message, setTextList) => {
    setTextList(prevTextList => {
        let updatedTextList = [...prevTextList];

        if (message.isNewMessage || updatedTextList.length === 0) {
            console.log("First loop");
            updatedTextList.push({ text: message.content, origin: "machine" });
        } else {
            let lastIndex = updatedTextList.length - 1;
            updatedTextList[lastIndex] = {
                ...updatedTextList[lastIndex],
                text: updatedTextList[lastIndex].text + message.content
            };
        }

        return updatedTextList;
    });
};

/* // Function to disconnect the WebSocket
export const disconnectWebSocket = (ws, setWs) => {
    if (ws) {
        ws.close();
        setWs(null);
    }
}; */