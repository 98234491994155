import React, {useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./Register.css";
import welcomeBackground from "../../assets/welcome-background.png";

function Register() {

    const navigate = useNavigate();
    const role = localStorage.getItem("role");

    const SortUserAdmin = () => {
        if (role === "admin"){
            console.log("admin");
            navigate("/signup/admin");
        } 
        if (role === "user"){
            console.log("user");
            navigate("/signup/user");
        } 
        if (!role) {
            navigate("/");
        }
    }

    useEffect(() => {
        SortUserAdmin();
    },[])

    return (
        <div className="container-fluid backGround">
            <div className="row">
                <div className="col-5">
                    <Outlet />
                </div>

                {/* Right half with purple background */}
                <div className="col-7 full-height">
                    <img src={welcomeBackground} alt="Welcome Background" className="welcomeBackground" />
                </div>
            </div>
        </div>
    );
}

export default Register;
