import SentimentElement from "../SentimentElement/SentimentElement.js";
import happy from "../../assets/happy.png";
import sad from "../../assets/sad.png";
import conflict from "../../assets/conflict.png";
import Relief from "../../assets/Relief.png";
import anger from "../../assets/anger.png";

function SentimentList({SentimentNumbers, setTextList, handlePromptSubmit}){

    const order = ["ANGER", "SAD", "CONFLICTED", "RELIEF", "CONTENTMENT"];

    function sortSentimentNumber() {
        if (Array.isArray(SentimentNumbers) && SentimentNumbers.length !== 0) {
            return SentimentNumbers.sort((a, b) => {
                return order.indexOf(a.name) - order.indexOf(b.name);
            });
        }
        return [];
    }
    const sortedSentimentNumbers = sortSentimentNumber();

    return(
        <div className="sentiment-elements">
            {sortedSentimentNumbers.map((sentiment, index) => {
                let icon;
                let color;
                switch(sentiment.name) {
                    case "ANGER":
                        icon = anger;
                        color = "#FF6F92";
                        break;
                    case "CONTENTMENT":
                        icon = happy;
                        color = "#4B9CFF";
                        break;
                    case "SADNESS":
                        icon = sad;
                        color = "#FF527C";//
                        break;
                    case "CONFLICTED":
                        icon = conflict;
                        color = "#B3D2F6";
                        break;
                    case "RELIEF":
                        icon = Relief;
                        color = "#73B2FF";
                        break;
                    default:
                        icon = happy;
                }
                return <SentimentElement key={index} name={sentiment.name} icon={icon} number={sentiment.value} color={color} setTextList={setTextList} handlePromptSubmit={handlePromptSubmit}/>
            })}
        </div>
    )
}

export default SentimentList;