
// Import Assets
import "./UtilButton.css";

function UtilButton({text, icon, handleClick}){
    return(
        <button className="util-button" onClick={handleClick}>
            {text}
            <img src={icon} alt="icon" className="util-button-icon"/>
        </button>
    )
}

export default UtilButton;