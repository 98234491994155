
//Import Assets
import "./NavigateButton.css";

function NavigateButton({text, icon, handleClick, leftIcon, highlight}){

    if (leftIcon){
        return(
            <button className="navigate-button" onClick={handleClick} style={{color: highlight ? "#fff" : "#808080", backgroundColor: highlight ? "#FF003E" : "#FFD4DF"}}>
                <img src={icon} alt="icon" className="navigate-button-icon"/>
                <p style={{marginRight: "8px"}} className="navigate-button-text">{text}</p>
            </button>
        )
    } else {
        return(
            <button className="navigate-button" onClick={handleClick} style={{color: highlight ? "#FFF" : "#808080", backgroundColor: highlight ? "#FF003E" : "#FFD4DF"}}>
                <p style={{marginLeft: "8px"}} className="navigate-button-text">{text}</p>
                <img src={icon} alt="icon" className="navigate-button-icon"/>
            </button>
        )
    }
    
}

export default NavigateButton;