import "./SentimentElement.css";

function DemographicElement({ text, icon, setDemographicBar}) {

    const handleClick = () => {
        setDemographicBar(true);
    }

    return (
        <button className="sentiment-element" onClick={handleClick}>
            <div className="sentiment-element-headerblock">
                <div className="sentiment-element-icon-box" style={{ backgroundColor: "#ffffff" }}>
                    <img src={icon} alt="icon" className="sentiment-element-icon" />
                </div>
                <div className="sentiment-element-text">
                    {text}
                </div>
            </div>
        </button>
    )
}

export default DemographicElement;
