
//import assets
import "./BackButton.css";

function BackButton({text, icon, handleClick}){
    return(
        <button className="back-button-body" onClick={handleClick}>
            <img src={icon} alt="icon" className="back-button-icon"/>
            <span className="back-button-body-text">{text}</span>
        </button>
    )
}

export default BackButton;