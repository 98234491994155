import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import './Sentiment.css';
import getEmotionAtIndex from '../utils/SentimentFunction';
import SentimentList from './renderList/SentimentList';
import DemographicList from './renderList/DemographicList';
import PageNoticeBlock from '../utils/Notice/Notice';


function Sentiment() {
    const { setTextList, setDemographicBar, selectedFilters, emotionData, selectedQuestion, handlePromptSubmit} = useOutletContext();
    const [ SentimentNumbers, setSentimentNumbers ] = useState({});

    useEffect(() => {
        setSentimentNumbers(getEmotionAtIndex(emotionData, selectedQuestion, selectedFilters));
    },[selectedFilters, emotionData, selectedQuestion])

    return (
        <div className="sentiment-box">
            <PageNoticeBlock 
            titleText={"Select the question!"} 
            bodyText={"If you want the chatbot to look at the responses associated with a specific question, click on 'select question' on your right!"}/>
            <div className="sentiment-list-box">
                <div>
                    <h1 className="sentiment-box-title">Demographic Filters</h1>
                    <DemographicList setDemographicBar={setDemographicBar} selectedFilters={selectedFilters}/>
                </div>
                <div>
                    <h1 className="sentiment-box-title">Sentiments</h1>
                    <SentimentList SentimentNumbers={SentimentNumbers.emotionCount} setTextList={setTextList} handlePromptSubmit={handlePromptSubmit} />
                </div>
            </div>
        </div>
    )
}
export default Sentiment;

/**
 * 
*/
/* const surveyData = [
    {
        EmotionLabel: [
            "CONTENTMENT",
            "CONTENTMENT"
        ],
        Question1: "option1",
        Question2: "option1",
        Question3: "option1"
    },
    {
        EmotionLabel: [
            "ANGER",
            "ANGER"
        ],
        Question1: "option1",
        Question2: "option1",
        Question3: "option1"
    },
    {
        EmotionLabel: [
            "SADNESS",
            "SADNESS"
        ],
        Question1: "option1",
        Question2: "option1",
        Question3: "option1"
    },
    {
        EmotionLabel: [
            "CONFLICTED",
            "CONFLICTED"
        ],
        Question1: "option1",
        Question2: "option1",
        Question3: "option1"
    },
    {
        EmotionLabel: [
            "RELIEF",
            "RELIEF"
        ],
        Question1: "option1",
        Question2: "option1",
        Question3: "option1"
    }
]; */