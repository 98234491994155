import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, speed }) => {
  // Split the text into an array of words
  const words = text.split(' ');

  const [index, setIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    // Check if there's more words to type out
    if (index < words.length) {
      // Set a timeout to type out the next word
      const timeoutId = setTimeout(() => {
        // Update the state with the current text plus the next word
        setCurrentText((ct) => `${ct}${ct ? ' ' : ''}${words[index]}`);
        // Increment the index to type out the next word
        setIndex((i) => i + 1);
      }, speed);

      // Clear the timeout if the component is unmounted
      return () => clearTimeout(timeoutId);
    }
  }, [index, words, speed]); // Dependencies array, re-run effect when these values change

  return (
    <div>{currentText}</div>
  );
};

export default Typewriter;