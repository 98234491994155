import React, { useState } from 'react';
import "./ClipboardText.css";
import clipboard from "../../assets/clipboard.png";

function ClipboardText({ text, originalTitle, index, UniqueID, handleDeleteClick, handleTitleChange }) {
    const [title, setTitle] = useState(originalTitle);
    const [isHovered, setIsHovered] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const handleDelete = () => {
        handleDeleteClick({index: index, UniqueID: UniqueID});
    }

    // Extracting the first 30 words
    const shortText = text.split(" ").slice(0, 30).join(" ") + (text.split(" ").length > 30 ? "..." : "");
    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const handleTitleSubmit = (e) => {
        e.preventDefault();
        handleTitleChange({index: index, UniqueID: UniqueID, Title: title});
    }

    return (
        <div 
            className="clipboard-text-block"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleExpandClick}
        >
            <form onSubmit={handleTitleSubmit}> {/* Add form tag here */}
                <input 
                    type="text" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)} 
                    className="clipboard-title"
                />
                <input type="submit" style={{ display: "none" }} /> {/* Hidden submit button */}
            </form>
            <div className="clipboard-body">
                <span className='clipboard-text-body-text' style={{ color: isHovered ? "#000000" : "#808080" }}>
                    {expanded ? text : shortText}
                </span>
            </div>
            {expanded && (
                <button className="clipboard-button" onClick={handleDelete}>
                    <img src={clipboard} alt="icon" className="clipboard-button-icon" />
                    <span className="clipboard-button-text">Remove from Clipboard</span>
                </button>
            )}
        </div>
    );
}

export default ClipboardText;
