import SideBar from "./SideBar/SideBar";
import NoticeBlock from "./Notice/Notice";
import QuestionSideBarElement from "./QuestionSideBarElement/QuestionSideBarElement";
import "./OverallSideBar.css";
import arrowBack from "../assets/arrowBack.svg";
import { useEffect, useState } from "react";

function SelectQuestionSideBar({ selectQuestionSideBar, closeSelectQuestionSidebar, questionData, setSelectedQuestion }) {

    //Set the question being rendered
    const [loadedQuestions, setLoadedQuestions] = useState([]);
    useEffect(() => {
        const initialQuestions = Array.isArray(questionData) && questionData.length !== 0
            ?
            questionData.map(question => ({
                Question: question.Question,
                Active: false,
            }))
            :
            null;
        setLoadedQuestions(initialQuestions);
    }, [questionData])

    //Set the selected question active
    const handleSelectQuestion = (index) => {
        const newQuestions = [...loadedQuestions];
        newQuestions.map(question => question.Active = false);
        newQuestions[index].Active = !newQuestions[index].Active;
        setLoadedQuestions(newQuestions);
    }

    return (
        <SideBar isOpen={selectQuestionSideBar} onClose={closeSelectQuestionSidebar} zHeight={1001}>
            <div className="clipboardsidebar-main">
                <button className="demographic-sidebar-back-button" onClick={closeSelectQuestionSidebar}>
                    <img src={arrowBack} alt="Back Arrow to homepage" />
                </button>
                <h1 className="demographic-sidebar-filter-title">
                    Select Question
                </h1>
                <NoticeBlock
                    titleText={"Choose a question"}
                    bodyText={"Select a question; the AI will answer questions about responses to that question."}>
                </NoticeBlock>
                <div className="selectquestion-text-box">
                    {
                        Array.isArray(loadedQuestions) ? loadedQuestions.map((question, index) => (
                            <QuestionSideBarElement
                                key={index}
                                text={question.Question}
                                index={index}
                                active={question.Active}
                                handleSelectQuestion={handleSelectQuestion}
                                setSelectedQuestion={setSelectedQuestion}
                            />
                        )) : <h1>
                            Loading questions...
                        </h1>
                    }
                </div>
            </div>

        </SideBar>
    )
}

export default SelectQuestionSideBar;