
// Import packages here
import ModalPopup from "../../Util/ModalPopup/ModalPopup";
import UtilButton from "../../Util/UtilButton/UtilButton";

// Import assets here
import "./AccessCodeModal.css";

function AccessCodeModal({ AccessCode, showModal, handleCloseModal, handleBackButton, handleCopyAccessCode, UtilButtonIcon }) {
    return (
    <ModalPopup show={showModal} onClose={handleCloseModal} closeText={"To Home Page"} handleBackButton={handleBackButton}>
        <div className='popup-body-box'>
            <h1 className='popup-body-title'>Congratulation!</h1>
            <p className='popup-body-subtitle'>Participants can access the survey with the access code below at <strong>play.factors.world</strong>. </p>
            <div className='popup-body-accesscode-box' onClick={handleCopyAccessCode}>
                <h2 className='popup-body-accesscode-text'>{AccessCode}</h2>
            </div>
            <br />
            <div className='popup-button-box-row'>
                {/* <UtilButton text={"Test out the survey!"} icon={UtilButtonIcon} handleClick={() => {
                    console.log("Test");
                }} /> */}
            </div>
        </div>
    </ModalPopup>
    );
}

export default AccessCodeModal;