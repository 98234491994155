import axios from 'axios';

export async function putSurvey({ AccessCode, TeamID, SurveyID=null, SurveyName, Questions, Filters }) {
    try {

        console.log("AccessCode: ", AccessCode);
        console.log("TeamID: ", TeamID);
        console.log("SurveyID: ", SurveyID);
        console.log("SurveyName: ", SurveyName);
        console.log("Questions: ", Questions);
        console.log("Filters: ", Filters);

        const SurveyData = {
            "AccessCode": AccessCode,
            "TeamID": TeamID,
            "SurveyID": SurveyID,
            "SurveyName": SurveyName,
            "Questions": Questions,
            "Filters": Filters
        }
        const response = await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/create/survey',
            JSON.stringify(SurveyData)
        );
        console.log(response.data);
        return response.data;

    } catch (error) {
        console.log(error);
        return null;
    }
}