import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

function Sorting(){

    const navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();

    useEffect(()=>{
        if(localStorage.getItem("role")){
            loginWithRedirect();
        } else {
            navigate("/welcome");
        }
    },[])
    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <span style={{fontSize: '30px'}}>Loading...</span>
        </div>
    )
}

export default Sorting;