import { useState } from 'react';

export function useSurvey(TeamID) {
    const [survey, setSurvey] = useState({
        TeamID: TeamID,
        SurveyID: null,
        SurveyName: null,
        AccessCode: null,
        SurveyQuestions: null,
        Filters: null
    });

    const setSurveyName = (SurveyName) => {
        setSurvey({ ...survey, SurveyName: SurveyName });
    }

    const setAccessCode = (AccessCode) => {
        setSurvey({ ...survey, AccessCode: AccessCode });
    }

    const setSurveyQuestions = (SurveyQuestions) => {
        setSurvey({ ...survey, SurveyQuestions: SurveyQuestions });
    }

    const setFilters = (Filters) => {
        setSurvey({ ...survey, Filters: Filters });
    }

    const setSurveyID = (SurveyID) => {
        setSurvey({ ...survey, SurveyID: SurveyID });
    }

    const getSurveyQuestions = () => {
        if (survey.SurveyQuestions) {
            return survey.SurveyQuestions;
        } else {
            console.log("Survey questions not set yet");
            return null;
        }
    }

    const getFilters = () => {
        if (survey.Filters) {
            return survey.Filters;
        } else {
            console.log("Filters not set yet");
            return null;
        }
    }

    const getTeamID = () => {
        if (survey.TeamID) {
            return survey.TeamID;
        } else {
            console.log("TeamID not set yet");
            return null;
        }
    }

    const checkCompletion = () => {
        if (survey.SurveyName && survey.AccessCode && survey.SurveyQuestions && survey.Filters) {
            console.log("Survey object is complete");
            return true;
        }
        console.log("Survey object is incomplete");
        return false;
    }

    return {
        survey,
        setSurveyName,
        setAccessCode,
        setSurveyQuestions,
        setFilters,
        setSurveyID,
        getSurveyQuestions,
        getFilters,
        getTeamID,
        checkCompletion
    };
}