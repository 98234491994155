import React from 'react';
import './SideBar.css'; // Import the CSS file

const SideBar = ({ isOpen, children, onClose, zHeight }) => {
  return (
    <>
      {isOpen && <div className="drawer-sidebar-overlay" onClick={onClose}></div>}
      <div className={`drawer-sidebar ${isOpen ? 'open' : ''} sidebar-container`} style={{zIndex: zHeight}}>
        {children}
      </div>
    </>
  );
};

export default SideBar;
