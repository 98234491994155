
//Import Assets
import buttonIconBlack from "../../../Assets/buttonIconBlack.svg";
import "./FunnelButton.css";

function FunnelButton(props) {
    const {handleClick, Title, Subtitle} = props;

    return (
        <div
            className={`funnel-button`}
            onClick={handleClick}
        >
            <div className="funnel-button-column">
                <h1 className="funnel-button-title">{Title}</h1>
                <h2 className="funnel-button-subtitle">{Subtitle}</h2>
            </div>
            <img src={buttonIconBlack} alt={"Button Icon"} className="funnel-button-icon"/>
        </div>
    );
}

export default FunnelButton;