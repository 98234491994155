import PromptElement from "../PromptElement/PromptElement.js";
import prompt_icon from "../../assets/prompt_icon.png";

function PromptList({PromptsList, setTextList, handlePromptSubmit, allowQuery}){

    return(
        <div className="summary-elements">
            {PromptsList.map((prompt, index) => (
                <PromptElement key={index} text={prompt} icon={prompt_icon} summary={false} setTextList={setTextList} handlePromptSubmit={handlePromptSubmit} allowQuery={allowQuery}/>
            ))}
        </div>
    )
}

export default PromptList;