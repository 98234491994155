import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import { 
  useAuth0, 
  withAuthenticationRequired 
} from '@auth0/auth0-react';
import AdminWelcomePage from "./components/Registration/WelcomePage/AdminWelcome/AdminWelcome";
import AdminNamePage from "./components/Registration/WelcomePage/AdminWelcome/NamePage";
import AdminSigninPage from "./components/Registration/WelcomePage/AdminWelcome/SigninPage";
import Register from "./components/Registration/Register/AdminRegistration/Register";
import CreateTeamSpace from "./components/Registration/Register/AdminRegistration/TeamSpace/CreateTeamSpace";
import InviteTeam from "./components/Registration/Register/AdminRegistration/InviteTeam/InviteTeam";
import RedirectComponent from "./components/Registration/Utils/RedirectComponent";
import HomePage from "./components/HomePage/HomePage";
import Results from "./components/NewResults/Results";
import Sentiment from "./components/NewResults/Sentiment/Sentiment";
import Prompts from "./components/NewResults/Prompts/Prompts";
import Sorting from "./components/Initial/Sorting";
import FunnelPage from "./components/Create/FunnelPage/FunnelPage";
import CreatePage from "./components/Create/CreatePage/CreatePage";
import AreaOfFocus from "./components/Create/AreaOfFocus/AreaOfFocus";
import EditQuestion from "./components/Create/EditQuestion/EditQuestion";
import AddFilter from "./components/Create/AddFilter/AddFilter";
import OptionsDetail from "./components/Create/AddFilter/OptionsDetail/OptionsDetail";

/* 
import CreateMainPage from "./components/Create/MainPage/MainPage";
import TopicSelectPage from "./components/Create/SurveyFlow/TopicPage/TopicPage";
import SelectTemplatePage from "./components/Create/SurveyFlow/TemplatePage/TemplatePage";
import SelectTemplate from "./components/Create/SurveyFlow/TemplatePage/SelectTemplate/SelectTemplate";
import TemplateDetail from "./components/Create/SurveyFlow/TemplatePage/TemplateDetail/TemplateDetail"; */

// NOTE: Currently sign out for the amplify app is designated to go to /

//Creating a protected route for the application
const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => (<div>Redirecting you to the login page...</div>)
  });
  return <Component />;
};

function App() {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return (
      <div className="position-fixed w-100 overflow-hidden">
        <div className="text-center d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      </div>);
  }

  if (error) {
    return (
      <div className="position-fixed w-100 overflow-hidden">
        <div className="d-flex justify-content-center align-items-center">
          <span className="text-danger">Oops... Seems like our server is down. Working hard to fix it, please try again soon.</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Routes>
        {/*
            Unprotected routes - Prior to sign in & Authentication
          */}
        <Route path="/" element={<Sorting />} />
        <Route path="/welcome" element={<AdminWelcomePage />}>
          <Route index element={<AdminNamePage />} />
          <Route path=":name" element={<AdminSigninPage />} />
        </Route>
        {
          /**
           * Protected routes - After sign in & Authentication
           */
        }
        <Route path="/redirect" element={<RedirectComponent />} />
        <Route path="/signup" element={<ProtectedRoute component={Register} />}>
          <Route path="/signup/admin" element={<ProtectedRoute component={CreateTeamSpace} />} />
          <Route path="/signup/admin/invite" element={<ProtectedRoute component={InviteTeam} />} />
        </Route>
        {
          /* 
          Home page - User management, survey management, survey creation
          */
        }
        <Route path="/home/:TeamID" element={<ProtectedRoute component={HomePage} />}/>
        {/* <Route path="/survey/:SurveyID" element={<SurveyDetails />} loader={SurveyDetailsLoader}>
          <Route index element={<SurveySettings />} />
          <Route path="templates" element={<SurveyTemplate />} loader={TemplateLoader}>
            <Route path=":templateID" element={<TemplateQuestions />} />
          </Route>
          <Route path="demographic" element={<Demographic />} loader={DemographicLoader}>
            <Route path=":demographicID" element={<DemographicPreview />} />
          </Route>
        </Route> */}
        {
          /*
          Survey results - Survey results page copilot and visualization
          */
        }
        <Route path="/results/:SurveyID" element={<ProtectedRoute component={Results} />}>
          <Route index element={<Prompts/>} />
          <Route path="sentiment" element={<Sentiment/>} />
        </Route> 
        <Route path="/funnel" element={<ProtectedRoute component={FunnelPage} />} />
        <Route path="/create" element={<ProtectedRoute component={CreatePage} />}>
          <Route index element={<ProtectedRoute component={AreaOfFocus} />} />
          <Route path="question" element={<ProtectedRoute component={EditQuestion} />} />
          <Route path="filter" element={<ProtectedRoute component={AddFilter} />}/>
          <Route path=":filterIndex" element={<ProtectedRoute component={OptionsDetail} />} />
        </Route>
        {/* <Route path="/create" element={<ProtectedRoute component={CreateMainPage} />}>
          <Route index element={<ProtectedRoute component={TopicSelectPage} />} />
          <Route path=":topic" element={<ProtectedRoute component={SelectTemplatePage} />}>
            <Route index element={<ProtectedRoute component={SelectTemplate} />} />
            <Route path=":templateID" element={<ProtectedRoute component={TemplateDetail} />} />
          </Route>
        </Route> */}
      </Routes>
    </>
  );
}

export default App;
