import SideBar from "./SideBar/SideBar";
import DemographicSideBarElement from "./DemographicSideBarElement/DemographicSideBarElement";
import { useState, useEffect } from "react";
import"./OverallSideBar.css";
import arrowBack from "../assets/arrowBack.svg";

function DemographicSideBar({demographicBar, closeSidebar, filters, setSelectedFilters, selectedFilters}){

    const [activeFilters, setActiveFilters] = useState([]);

    const handleFilterActive = (index, updatedFilters) => {
        const isActive = updatedFilters.length !== 0;

        //Update the active filters state for the sidebar visualization
        const newActiveFilters = [...activeFilters];
        newActiveFilters[index].active = isActive;
        setActiveFilters(newActiveFilters);

        /* //Update the selected filters state for sentimentFunction logic
        //Generally don't temper with this; it's a bit tricky
        const newSelectedFilters = [...selectedFilters];     
        newSelectedFilters[index].active = isActive;
        setSelectedFilters(newSelectedFilters); */

        console.log("The filter active state is: " + selectedFilters[index].active)

        return isActive;
    }

    useEffect(() => {
        const initialFilters = Array.isArray(filters) && filters.length !==0 ? 
            filters.map(filter => ({ filterKey: filter.filterKey, active: false, filterVal: filter.filterVal })
            ):
            [{ filterKey: "No filters available", active: false, filterVal: [{identifier:"Loading values...", display:"Loading values..."}] }];
        setActiveFilters(initialFilters);
    }, [filters]);

    return(
        <SideBar isOpen={demographicBar} onClose={closeSidebar} zHeight={1001}>
            <button className="demographic-sidebar-back-button" onClick={closeSidebar}>
                <img src={arrowBack} alt="Back Arrow to homepage" />
            </button>
            <h1 className="demographic-sidebar-filter-title">
                Demographic Filters
            </h1>
            {activeFilters && activeFilters.map((filter, index) => (
                <DemographicSideBarElement
                    key={index}
                    index={index}
                    active={filter.active}
                    text={filter.filterKey}
                    values={filter.filterVal}
                    handleFilterActive={handleFilterActive}
                    selectedFilters={selectedFilters[index]}
                    setSelectedFilters={setSelectedFilters}
                />
            ))}
        </SideBar>
    )
}

export default DemographicSideBar;