import ToolBarElement from "./ToolBarElement/ToolBarElement";
import "./ToolBar.css";

function ToolBar({setSelectQuestionSideBar}){
    const onClick = () => {
        console.log("Clicked");
        setSelectQuestionSideBar(true);
    }
    return(
        <div className="toolsbar-box">
            <ToolBarElement onClick={onClick}/>
        </div>
    )
}

export default ToolBar;