
//Import dependencies and libraries
import React, { useState }from 'react';

//Import Assets
import './ModalPopup.css'; 
import modalBackIcon from '../../../Assets/modalBackIcon.svg';
import modalBackIconRed from '../../../Assets/modalBackIconRed.svg';

const ModalPopup = ({ show, children, onClose, closeText, handleBackButton }) => {
    const [isHovered, setIsHovered] = useState(false);

    if (!show) {
        return null;
    }

    const content = children || <div>No content provided</div>;
    
    const handleBackdropClick = (e) => {
        onClose();
    };

    const handleModalClick = (e) => {
        // Prevent click inside the modal from closing it
        e.stopPropagation();
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const modalBackIconSrc = isHovered ? modalBackIconRed : modalBackIcon;

    return (
        <div className="modal-popup-backdrop" onClick={handleBackdropClick}>
            <div className="modal-popup" onClick={handleModalClick}>
                <button
                    onClick={handleBackButton}
                    className='modal-back-button'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <img src={modalBackIconSrc} className='modal-back-icon'/>
                    {closeText}
                </button>
                <div className='modal-body-children'>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default ModalPopup;
