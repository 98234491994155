
// Import Dependencies
import ModalPopup from "../../Util/ModalPopup/ModalPopup";

//Import Assets
import "./PublishModal.css";

function PublishModal({ 
    showPublishModal, 
    handleClosePublishModal, 
    publishWarning, 
    surveyNameInput, 
    setSurveyNameInput, 
    handlePublish, 
    setAccessCode, 
    survey, 
    setSurveyName,
    setSurveyID,
    setError 
}) 
{
    return (
        <ModalPopup show={showPublishModal} onClose={handleClosePublishModal} closeText={"Edit the Survey"} handleBackButton={handleClosePublishModal}>
            <div className='popup-body-box'>
                <h1 className='popup-body-title'>Publish Survey?</h1>
                <p className='popup-body-subtitle'>Give your survey a name, and then click "Next". </p>
                {publishWarning ? <p className='popup-body-subtitle' style={{
                    color: "#FF003E"
                }}>{publishWarning}</p> : null}

                <div className="popup-body-row-input-field">
                    <input
                        type="text"
                        placeholder="Enter a Survey Name..."
                        value={surveyNameInput}
                        onChange={e => setSurveyNameInput(e.target.value)}
                        className="popup-body-input-field"
                    />
                    <button
                        onClick={() => handlePublish(setAccessCode, survey, setSurveyName, setSurveyID, setError)}
                        className="popup-body-input-button"
                    >
                        Publish!
                    </button>
                </div>
                <br />
            </div>
        </ModalPopup>
    );
}

export default PublishModal;