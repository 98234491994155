// Date: 12/15/2023

// Import dependencies & libraries
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

// Import components here
import CreatePanelTitle from "../Util/CreatePanelTitle/CreatePanelTitle";
import CreateElementTabs from "../Util/CreateElementTab/CreateElementTab";
import NavigateButton from "../Util/NavigateButton/NavigateButton";
import QuestionTabs from "./QuestionTabs/QuestionTabs";

// Import assets here
import "./EditQuestion.css";
import { useHover } from "../Util/Hooks";
import QuestionIcon from "../../Assets/StaticIcons/QuestionIcon.svg";
import BackArrowGrey from "../../Assets/StaticIcons/BackArrowGrey.svg";
import ForwardArrowWhite from "../../Assets/StaticIcons/ForwardArrowWhite.svg";

/**
* This widget should take the generated questions in @param questions to initialize the display
* And when the user have in anyways edited the questions, it should initialize with the @param Survey.getSurveyQuestions() 
* if the user return to this page.
*/

function EditQuestion() {
    
    const { 
        navigate,
        getSurveyQuestions, 
        setSurveyQuestions, 
        questions, 
        edited, 
        handleQuestionSubmit 
    } = useOutletContext();

    const [editedQuestions, setEditedQuestions] = useState(edited ? getSurveyQuestions() : questions);
    const [warningMessage, setWarningMessage] = useState("");

    const { 
        hoveredIndex, 
        handleMouseEnter, 
        handleMouseLeave 
    } = useHover(editedQuestions);
    
    useEffect(() => {
        setEditedQuestions(edited ? getSurveyQuestions() : questions);
    },[questions]);

    useEffect(() => {
        if (editedQuestions?.length < 4) {
            setWarningMessage("");
        }
    }, [editedQuestions]);

    const handleStoreAndNavigate = () => {
        if (editedQuestions.length === 0) {
            setWarningMessage("Please enter at least one question.");
            return;
        }
        setSurveyQuestions(editedQuestions);
        handleQuestionSubmit(editedQuestions);
        navigate("/create/filter");
    }

    const handleCompleteEdit = (index, value) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions[index] = value;
        setEditedQuestions(updatedQuestions);
    };

    const handleRemove = (index) => {
        const updatedQuestions = [...editedQuestions];
        updatedQuestions.splice(index, 1);
        setEditedQuestions(updatedQuestions);
    };

    const handleAddQuestion = () => {
        if (editedQuestions.length < 4) {
            const updatedQuestions = [...editedQuestions];
            updatedQuestions.push("");
            setEditedQuestions(updatedQuestions);
        } else {
            setWarningMessage("Maximum 4 questions reached.");
        }
    }

    return (
        <div className="create-function-panel-background">
            <div className="create-function-panel-body">
                <div>
                    <div className="create-function-panel-title-row">
                        <CreatePanelTitle titleText="Edit Questions" />
                        <button className="create-function-panel-button" onClick={handleAddQuestion}>Add Question</button>
                    </div>
                    {warningMessage && <p className="area-of-focus-instruction-text space-left-3vw">{warningMessage}</p>}
                    {editedQuestions ? (
                        editedQuestions.map((question, index) => (
                                <CreateElementTabs
                                    key={index}
                                    icon={QuestionIcon}
                                    state={hoveredIndex[index]}
                                    handleMouseEnter={() => handleMouseEnter(index)}
                                    handleMouseLeave={handleMouseLeave}
                                    handleRemove={() => handleRemove(index)}
                                >
                                    <QuestionTabs
                                        question={question}
                                        index={index}
                                        handleCompleteEdit={handleCompleteEdit}
                                    />
                                </CreateElementTabs>
                        ))
                    ) : (
                        <div className="create-function-panel-loading-box">
                            <div className="create-function-spacer-150px-vertical" />
                            <div className="spinner-border" role="status"></div>
                            <span className="sr-only">Generating questions...</span>
                        </div>
                    )}
                </div>
                <div className="create-function-panel-bottom-buttons">
                    <NavigateButton text="Back to Focus" icon={BackArrowGrey} handleClick={() => navigate("/create")} leftIcon={true} />
                    <NavigateButton text="Next" icon={ForwardArrowWhite} handleClick={handleStoreAndNavigate} highlight={true} />
                </div>
            </div>
        </div>
    );
}

export default EditQuestion;