import axios from 'axios';

export async function DeleteClipboard ({TeamID, SurveyID, UniqueID}) {
    
    try {
        console.log("Calling api");
        const data = {
            TeamID: TeamID,
            SurveyID: SurveyID,
            UniqueID: UniqueID
        };
        await axios.post('https://r90zrc5ijj.execute-api.us-east-1.amazonaws.com/dev/result/clipboard/delete',
            JSON.stringify(data)
        );

    } catch (error) {
        console.log(error);
        return null;
    }
}