import React from "react";
import { Outlet } from "react-router-dom";
import "./AdminWelcome.css";
import welcomeBackground from "../../assets/welcome-background.png";

function AdminWelcomePage() {

  return (
    <div className="container-fluid backGround">
      <div className="row">
        <div className="col-5">
          <Outlet />
        </div>

        {/* Right half with purple background */}
        <div className="col-7 full-height">
          <img src={welcomeBackground} alt="Welcome Background" className="welcomeBackground" />
        </div>
      </div>
    </div>
  );
}

export default AdminWelcomePage;
