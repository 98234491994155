
import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

//Creating the Auth0 provider for the application
const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain="dev-266428w8ajrsz85h.us.auth0.com"
        clientId="UYLs7mr6TksCv6twJtf99ZnSEeBTIfAq"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/redirect`
        }}
      >
        <App />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
);

