import "./Notice.css";
import NoticeIcon from "../../assets/notice_icon.svg";

function NoticeBlock({titleText, bodyText}){
    return(
        <div className="notice-block">
            <div className="notice-body">
                <img src={NoticeIcon} alt="Back Arrow to homepage" className="notice-icon"/>
                <h1 className="notice-title text-shared">{titleText}</h1>
            </div>
            <p className="notice-text text-shared">{bodyText}</p>
        </div>
    )
}

export default NoticeBlock;