import "./textField.css";

function TextField({ handleSubmit, input, setInput, placeholder, allowQuery }) {

  if (allowQuery === false) {
    return (
      <form onSubmit={handleSubmit} className="textfield-form">
      <input
        type="text"
        className="textfield-input"
        placeholder={placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        disabled
      />
      <button className="textfield-submit-button" type="submit" disabled>
        Submit
      </button>
  </form>
    )
  }
  return (
    <form onSubmit={handleSubmit} className="textfield-form">
        <input
          type="text"
          className="textfield-input"
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
        <button className="textfield-submit-button" type="submit">
          Submit
        </button>
    </form>
  )
}

export default TextField;